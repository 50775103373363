import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DocumentType,
    Query,
    SortDirection,
    StatusType,
} from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { MAX_PAGINATION_PAGE_SIZE } from '../../constants/api.constants';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class DocumentTypesApiService {
    defaultPageSize = 50;

    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getAll(
        paginationOptions?: PaginationOptions
    ): Observable<PaginatedApiResponse<DocumentType>> {
        const headers = new HttpHeaders(
            this.apiPaginationService.mapPaginationOptions({
                ...paginationOptions,
                pageSize: paginationOptions?.pageSize || this.defaultPageSize,
            })
        );
        return this.http.get<PaginatedApiResponse<DocumentType>>(
            `${this.config.getConfiguration().API_BASE}/documenttype`,
            { headers }
        );
    }

    getActiveDocumentTypes(pageNumber = 1) {
        const headers = new HttpHeaders(
            this.apiPaginationService.mapPaginationOptions({
                pageNumber,
                pageSize: MAX_PAGINATION_PAGE_SIZE,
            })
        );
        return this.http.get<PaginatedApiResponse<DocumentType>>(
            `${
                this.config.getConfiguration().API_BASE
            }/documenttype?activeOnly=true`,
            { headers }
        );
    }

    updateDocumentTypes(
        paginationOptions: PaginationOptions
    ): Observable<PaginatedApiResponse<DocumentType>> {
        return this.getAll(paginationOptions);
    }

    queryDocumentTypes(
        query: Query
    ): Observable<PaginatedApiResponse<DocumentType>> {
        const headers = new HttpHeaders(
            this.apiPaginationService.mapPaginationOptions({ pageSize: 200 })
        );
        return this.http.post<PaginatedApiResponse<DocumentType>>(
            `${this.config.getConfiguration().API_BASE}/query/documenttype`,
            query,
            { headers }
        );
    }

    disableDocumentType(
        code: string,
        isDisabled: boolean
    ): Observable<DocumentType> {
        const status: StatusType = isDisabled
            ? StatusType.Inactive
            : StatusType.Active;

        return this.http.patch<DocumentType>(
            `${
                this.config.getConfiguration().API_BASE
            }/documenttype/${code}/status/${status}`,
            {}
        );
    }

    getDocumentTypeDetail(documentTypeCode: string): Observable<DocumentType> {
        return this.http.get<DocumentType>(
            `${
                this.config.getConfiguration().API_BASE
            }/documenttype/code?code=${documentTypeCode}`
        );
    }

    getSearchSort(sortDirection, a, b) {
        return sortDirection === SortDirection.Ascending
            ? a > b
                ? 1
                : -1
            : a > b
            ? -1
            : 1;
    }

    deleteDocumentType(code: string): Observable<DocumentType> {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/documenttype/${code}`
        );
    }
}
