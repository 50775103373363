import { Inject, Injectable } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { ChartsConfigService } from '@wdx/charts';
import { ApiConfigService } from '@wdx/clmi/api-services/services';
import { Config, IGetConfig } from '@wdx/shared/utils';
import { APP_CONFIG } from '../app.config';
import { DynamicScriptService } from './dynamic-script.service';
import { SentryService } from './sentry.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigService implements IGetConfig {
    config: Config;

    constructor(
        @Inject(APP_CONFIG) config: Config,
        private authClientConfig: AuthClientConfig,
        private dynamicScriptService: DynamicScriptService,
        private sentryService: SentryService,
        private apiConfigService: ApiConfigService,
        private chartsConfigService: ChartsConfigService,
    ) {
        this.config = config;
    }

    load() {
        return new Promise<void>((resolve) => {
            if (this.config.pingdom) {
                this.dynamicScriptService.load([
                    `//rum-static.pingdom.net/${this.config.pingdom}.js`,
                ]);
            }

            if (this.config?.Sentry?.SentryAPI) {
                this.sentryService.init(this.config);
            }

            this.authClientConfig.set({
                domain: this.config.AuthenticationResource,
                clientId: this.config.AuthenticationClientId,
                authorizationParams: {
                    audience: this.config.AuthenticationAudience,
                    redirect_uri: window.location.origin,
                    scope: 'access:user',
                },
                useRefreshTokens: true,
                cacheLocation: 'localstorage',
                useRefreshTokensFallback: true,
            });

            this.apiConfigService.set({ apiBaseUrl: this.config.API_BASE });
            this.chartsConfigService.set();

            resolve();
        });
    }

    getConfiguration(): Config {
        return this.config;
    }
}
