import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';

export const customCurrencyMaskConfig: NgxCurrencyConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: ',',
    nullable: true,
    min: undefined,
    max: undefined,
    inputMode: NgxCurrencyInputMode.Natural,
};

export const currencyMaskNumberConfigOverride: Partial<NgxCurrencyConfig> = {
    thousands: ',',
    decimal: '',
    precision: 0,
};
