<atom-modal-wrapper
    [modalId]="IMPORT_ENTITIES_DOWNLOAD_MODAL_ID"
    [modalTemplate]="entitiesModal"
>
    <ng-template #entitiesModal>
        <organism-modal
            [modalTitle]="modalTitle"
            (modalClosed)="onModalClosed()"
            data-cy="modal-all-entities-download"
        >
            <div
                class="mh-100 overflow-auto"
                wdxListGroup
                [wdxListGroupFlush]="true"
                [wdxIsLoading]="importEntitiesIsLoading$ | ngrxPush"
                [hasError]="importEntitiesHasError$ | ngrxPush"
            >
                @if (!selectedCategory) {
                    @for (
                        category of importEntities$ | ngrxPush;
                        track category.category
                    ) {
                        <button
                            wdxListGroupItem
                            [wdxListGroupAction]="true"
                            (click)="selectCategory(category)"
                        >
                            <wdx-icon
                                class="me-2"
                                [fixedWidth]="true"
                                [icon]="category.icon"
                            ></wdx-icon>
                            {{
                                category.categoryName?.key
                                    | translateTokenisedString
                            }}
                        </button>
                    }
                }

                @if (selectedCategory) {
                    <button
                        wdxListGroupItem
                        [wdxListGroupAction]="true"
                        (click)="onBackToCategoriesList()"
                    >
                        <wdx-icon
                            class="me-2"
                            [fixedWidth]="true"
                            [icon]="ICON_CHEVRON_LEFT"
                        ></wdx-icon>
                        {{
                            selectedCategory.categoryName?.key
                                | translateTokenisedString
                        }}
                    </button>
                    @for (
                        importType of selectedCategory.importTypes;
                        track importType.code
                    ) {
                        <button
                            wdxListGroupItem
                            [wdxListGroupAction]="true"
                            (click)="onDownload(importType.code)"
                            [attr.data-cy]="'import-action-' + importType.code"
                        >
                            <wdx-icon
                                class="me-2"
                                [fixedWidth]="true"
                                icon="download"
                            ></wdx-icon>
                            {{
                                importType.displayName.key
                                    | translateTokenisedString
                            }}
                        </button>
                    }
                }
            </div>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
