import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    inject,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';

import { PushPipe } from '@ngrx/component';
import { WdxButtonStyle } from '@wdx/shared/components/wdx-button';
import {
    Country,
    FeatureFlag,
    FeaturesService,
    FormDefinition,
    MANUAL_ADDRESS_FORM_ID,
    PendingChangeStatusType,
    PostalAddress,
    PostalAddressLookup,
} from '@wdx/shared/utils';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    take,
    takeUntil,
} from 'rxjs/operators';
import { addressesReducers } from '../../../+state/addresses';
import * as addressesActions from '../../../+state/addresses/addresses.actions';
import * as addressesSelectors from '../../../+state/addresses/addresses.selectors';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import {
    LayoutAndDefinitionEntry,
    ReactiveFormLayoutAndDefinition,
    ValidationSummaryError,
    ValidationSummarySection,
} from '../../../models';
import {
    FormConditionsService,
    FormProcessConditionDataService,
    FormValidationService,
} from '../../../services';
import { AddressFormatService } from './services/address-format.service';
import { AddressTypeService } from './services/address-type.service';

@Component({
    selector: 'wdx-ff-address-control',
    templateUrl: './form-address-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FormConditionsService, PushPipe, AddressTypeService],
})
export class FormAddressControlComponent
    extends BaseWdxFormControlClass
    implements OnInit, OnChanges
{
    @ViewChild(NgSelectComponent) addressSelect!: NgSelectComponent;

    countries$!: Observable<Country[]>;
    addressSearchResults$: Observable<PostalAddressLookup[]> = of([]);
    addressSearchResultsIsLoading$!: Observable<boolean>;
    fullAddress$!: Observable<PostalAddress>;
    fullAddressIsLoading$!: Observable<boolean>;
    fullAddressHasError$!: Observable<boolean>;
    identifier$ = new BehaviorSubject<string>(null as any);
    hasValue$ = new BehaviorSubject<boolean>(false);
    smartAddressFields$ = new BehaviorSubject<string[]>(null as any);
    validationSummary$ = new BehaviorSubject<ValidationSummarySection>(
        null as any
    );
    hasValidators = false;

    addressInput$ = new Subject<string>();
    isRequired$ = new Subject<boolean>();

    hasAddress = false;
    addressLookupEnabled!: boolean;
    isSmartView = false;
    requiredFields!: string[];
    controlId = Date.now().toString();
    WdxButtonStyle = WdxButtonStyle;
    addressFormDefinition!: FormDefinition;
    index?: number;
    fieldsToNullCheck: string[] = [];
    formControls!: FormGroup;
    layoutAndDefinitionEntry!: LayoutAndDefinitionEntry[];

    public condService = inject(FormConditionsService);
    public addressTypeService = inject(AddressTypeService);
    private addressFormatService = inject(AddressFormatService);
    private elementRef = inject(ElementRef);
    private store$ = inject(
        Store<{
            [addressesReducers.FEATURE_KEY]: addressesReducers.State;
        }>
    );
    private featuresService = inject(FeaturesService);
    private formValidationService = inject(FormValidationService);
    private formProcessService = inject(FormProcessConditionDataService);
    private cd = inject(ChangeDetectorRef);

    get addressDisableAllFields(): boolean {
        return Boolean(
            this.formElement.pendingChange &&
                !this.formElement.pendingChange?.subItems?.length
        );
    }

    ngOnInit(): void {
        this.configureAddressLookup();

        this.countries$ = this.dynamicDataService.getCountries();

        this.dynamicDataService
            .getFormLayoutAndDefinition(MANUAL_ADDRESS_FORM_ID)
            .pipe(take(1))
            .subscribe(({ definition }: any) => {
                this.formControls = (
                    this.controlContainer.control as FormGroup
                ).get(this.formElement.name as string) as FormGroup;
                const DEFINITION = this.addressTypeService.overrideDefinition(
                    definition,
                    this.formElement
                );

                this.addressFormDefinition = DEFINITION;
                this.setUpManualAddress(DEFINITION);

                this.condService.configService(
                    this.formControls,
                    DEFINITION,
                    this.formStaticService.formData,
                    this.formStaticService.formId,
                    this.fieldsToNullCheck,
                    this.formStaticService.initialisationMode
                );

                this.condService.layoutAndDefinition$
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe({
                        next: (layoutAndDefinitionEntry) => {
                            layoutAndDefinitionEntry?.[0]?.layoutAndDefinition?.forEach(
                                (field) => {
                                    const PENDING_CHANGE =
                                        this.formElement?.pendingChange?.subItems?.find(
                                            (subItem) =>
                                                subItem ===
                                                (field.name as string)
                                        );

                                    if (
                                        this.addressDisableAllFields ||
                                        PENDING_CHANGE
                                    ) {
                                        field.pendingChange = {
                                            status: this.addressDisableAllFields
                                                ? PendingChangeStatusType.Submitted
                                                : this.formElement
                                                      ?.pendingChange?.status,
                                        };
                                    }
                                }
                            );

                            this.layoutAndDefinitionEntry = [
                                ...layoutAndDefinitionEntry,
                            ];
                        },
                    });

                this.applyValidators(
                    !!this.formControl?.hasValidator(Validators.required)
                );
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['formElement'].currentValue) {
            const formElement = changes['formElement']
                .currentValue as ReactiveFormLayoutAndDefinition;
            // trigger only when the id changes, i.e. when control is used in an array if an earlier element is deleted
            // then the id of this address will be reduced by 1
            if (
                this.index &&
                this.index !== formElement.index &&
                this.addressFormDefinition
            ) {
                this.applyLayout(this.addressFormDefinition);
            }
            this.index = formElement.index;
            this.isRequired$.next(Boolean(formElement.isRequired));
        }
    }

    setUpManualAddress(definition: FormDefinition) {
        this.addFormControls(definition);
        this.applyLayout(definition);

        this.cd.detectChanges();

        this.requiredFields = this.formElement.children
            ?.filter((child) => child.isRequired)
            .map((child) => child.name) as string[];

        this.setHasValue(this.formControl?.getRawValue());

        this.formControl?.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.setHasValue(value);
            });

        this.isRequired$
            .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
            .subscribe((isRequired) => this.applyValidators(isRequired));

        this.formStaticService.submitAttempted$.pipe(take(1)).subscribe(() => {
            this.formControl?.markAsTouched();
            this.formControl?.updateValueAndValidity();
            Object.keys((this.formControl as FormGroup).controls).forEach(
                (controlId) => {
                    const control = this.formControl?.get(controlId);
                    control?.markAsTouched();
                    this.cd.detectChanges();
                }
            );
            this.calculateValidationErrors();
            this.controlContainer.control?.statusChanges
                .pipe(takeUntil(this.formStaticService.destroyed$))
                .subscribe(() => {
                    this.calculateValidationErrors();
                });
        });
    }

    setHasValue(value: any) {
        const hasValue = Object.keys(value).some((key) => Boolean(value[key]));

        this.hasValue$.next(hasValue);

        if (this.addressLookupEnabled) {
            this.addressFormatService
                .getAddress$(this.formControl?.getRawValue(), true)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((value) => {
                    this.smartAddressFields$.next(value);
                });
        }
    }

    calculateValidationErrors() {
        this.validationSummary$.next({
            errors: this.formValidationService.getDefinitionErrors(
                this.formElement.children as ReactiveFormLayoutAndDefinition[],
                (this.formControl as any).controls
            ) as any,
        });
    }

    applyValidators(isRequired: boolean) {
        if (isRequired) {
            this.setValidators();
        } else {
            this.hasValue$
                .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
                .subscribe((hasValue) => {
                    if (hasValue) {
                        this.setValidators();
                    } else {
                        this.resetValidators();
                    }
                });
        }
    }

    resetValidators() {
        this.layoutAndDefinitionEntry?.[0].layoutAndDefinition?.forEach(
            (definition) => {
                if (this.requiredFields.includes(definition.name as string)) {
                    definition.isRequired = false;
                }
                const control = this.formControl?.get(
                    definition.name as string
                );
                if (control) {
                    control.setValidators(null);
                    control.updateValueAndValidity();
                }
            }
        );

        this.addressTypeService.setAddressTypeValue(
            this.formControls,
            this.hasValue$.value
        );
        this.condService.resetFormSectionLayoutDefinitions();
        this.cd.detectChanges();
    }

    setValidators() {
        this.addressTypeService.setAddressTypeValue(
            this.formControls,
            this.hasValue$.value
        );
        this.condService.resetFormSectionLayoutDefinitions();
        this.layoutAndDefinitionEntry?.[0].layoutAndDefinition?.forEach(
            (definition) => {
                if (this.requiredFields.includes(definition.name as string)) {
                    definition.isRequired = true;
                }
                const validators =
                    this.formValidationService.getValidators(definition);
                if (validators) {
                    const control = this.formControl?.get(
                        definition.name as string
                    );
                    if (control) {
                        control.addValidators(validators);
                        control.updateValueAndValidity();
                    }
                }
            }
        );
        this.cd.detectChanges();
    }

    convertValueIn(value: Record<string, any>) {
        Object.keys(value).forEach((key) => {
            if (this.formControl?.get(key)) {
                this.formControl.get(key)?.setValue(value[key]);
            }
        });
        this.hasAddress = true;
    }

    configureAddressLookup() {
        this.addressLookupEnabled = this.featuresService.hasFeature(
            FeatureFlag.AddressLookupEnabled
        );

        if (this.addressLookupEnabled) {
            this.isSmartView = true;
            this.loadAddressLookup();
            this.loadAddressSelectors();
        }
    }

    loadAddressSelectors() {
        this.addressSearchResults$ = this.store$
            .select(addressesSelectors.getAddresses, {
                id: this.controlId,
            })
            .pipe(map((addresses) => addresses || []));

        this.addressSearchResultsIsLoading$ = this.store$
            .select(addressesSelectors.getAddressesIsLoadingList, {
                id: this.controlId,
            })
            .pipe(map(Boolean));

        this.fullAddressIsLoading$ = this.store$
            .select(addressesSelectors.getAddressIsLoadingSingle, {
                id: this.controlId,
            })
            .pipe(map(Boolean));

        this.fullAddressHasError$ = this.store$
            .select(addressesSelectors.getAddressHasLoadingSingleError, {
                id: this.controlId,
            })
            .pipe(map(Boolean));

        this.fullAddress$ = this.store$.select(addressesSelectors.getAddress, {
            id: this.controlId,
        }) as Observable<PostalAddress>;
    }

    loadAddressLookup() {
        this.addressInput$
            .pipe(
                takeUntil(this.destroyed$),
                debounceTime(300),
                filter((searchText) => Boolean(searchText))
            )
            .subscribe((searchText) => {
                this.store$.dispatch(
                    addressesActions.getAddresses({
                        searchText: searchText,
                        fieldId: this.controlId,
                    })
                );
            });
    }

    reset() {
        Object.keys(this.formControl?.getRawValue()).forEach((key) => {
            this.formControl?.get(key)?.setValue(null);
        });
        this.hasAddress = false;
    }

    trackByFn(address: PostalAddressLookup): string {
        return address.identifier as string;
    }

    onLookupSearch(event: { term: string }): void {
        const { term } = event;

        if (!term) {
            return;
        }

        this.store$.dispatch(
            addressesActions.getAddresses({
                searchText: term,
                fieldId: this.controlId,
            })
        );
    }

    onLookupClear(): void {
        this.addressSelect.clearModel();
        this.reset();
    }

    onLookupSelected(address: PostalAddressLookup): void {
        if (!address) {
            this.reset();
            return;
        }

        const { identifier } = address;

        this.store$.dispatch(
            addressesActions.getAddress({
                addressId: identifier as string,
                fieldId: this.controlId,
            })
        );

        this.fullAddress$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((address) => {
                if (!address) {
                    return;
                }
                this.convertValueIn({
                    ...address,
                });
            });
    }

    /**
     * This method creates the form controls and figures out what (if any) data to use
     *
     * Full Details:
     * The FF Address Control (this component) is different from all other FF controls - it adds it's own form controls using the
     * ManualAddressForm formDef. Also remember this control can be used either directly embedded in a form or as part of an array/subform.
     * When used in an array we need to handle the scenario where the API formData contains data for an array of addresses, if one of these
     * addresses is deleted from the parent form and the user creates a new address entry (re-using the same index) we need to detect this and
     * create an empty address instance.
     *
     * @param formDefinition ManualAddressForm formDefinition
     */
    addFormControls(formDefinition: FormDefinition) {
        const formData = this.formStaticService.formData.data;
        const arrayMode = typeof this.formElement.index === 'number';

        let data =
            this.formElement.parentName && formData[this.formElement.parentName]
                ? formData[this.formElement.parentName][
                      this.formElement.index as number
                  ]
                : formData;

        // create/check initDone flag on data, this is so we can detect if we've already initialised a component instance with this data
        if (arrayMode && data) {
            if (data?.initDone) {
                data = undefined;
            } else {
                data.initDone = true;
            }
        }

        formDefinition?.layout?.sectionLayoutDefinitions?.[0].elementLayoutDefinitions?.forEach(
            (definition) => {
                const control = new FormControl(
                    data && data[this.formElement.name as string]
                        ? data[this.formElement.name as string][
                              definition.name as string
                          ]
                        : null,
                    this.formValidationService.getValidators(definition)
                );

                (this.formControl as FormGroup).addControl(
                    definition.name as string,
                    control
                );
            }
        );
    }

    applyLayout(formDefinition: FormDefinition): void {
        this.formElement.children =
            formDefinition?.layout?.sectionLayoutDefinitions?.[0].elementLayoutDefinitions?.map(
                (innerElement) => {
                    const definition = {
                        ...formDefinition.schema?.find(
                            (schemaItem) =>
                                schemaItem.name === innerElement.name
                        ),
                        ...innerElement,
                    };

                    const controlToUpdate = this.formControl?.get(
                        innerElement.name as string
                    ) as UntypedFormControl;

                    this.formProcessService.updateValidation(
                        controlToUpdate,
                        definition
                    );
                    return definition;
                }
            );
    }

    onErrorClicked(error: ValidationSummaryError): void {
        const fieldDefinitionElementRef =
            this.elementRef.nativeElement.querySelector(
                `[data-form-control='${error.name}']`
            );
        this.formValidationService.scrollToError(fieldDefinitionElementRef);
    }

    onToggleView() {
        this.isSmartView = !this.isSmartView;
    }
}
