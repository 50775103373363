import { Action, createReducer, on } from '@ngrx/store';
import { Region } from '@wdx/clmi/api-models';
import * as regionsActions from './regions.actions';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
export interface State {
    region: CrudStateObject<Region>;
}

export const initialState: State = {
    region: {},
};

export const GLOBAL_STATE_INDEX_ID = 'GLOBAL_STATE_INDEX_ID';

const reducerSetup = createReducer(
    initialState,

    on(
        regionsActions.getRegions,
        (state, props): State => ({
            ...state,
            region: {
                ...state.region,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.region[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Region>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    filteredList: props.reset
                        ? []
                        : state.region[GLOBAL_STATE_INDEX_ID]?.filteredList,
                },
            },
        })
    ),

    on(
        regionsActions.getRegionsSuccess,
        (state, props): State => ({
            ...state,
            region: {
                ...state.region,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.region[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    filteredList: [
                        ...(state.region[GLOBAL_STATE_INDEX_ID].filteredList ||
                            []),
                        ...props.regions,
                    ],
                },
            },
        })
    ),

    on(
        regionsActions.getRegionsFailure,
        (state): State => ({
            ...state,
            region: {
                ...state.region,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.region[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),
    on(
        regionsActions.disableRegion,
        (state): State => ({
            ...state,
            region: {
                ...state.region,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.region[GLOBAL_STATE_INDEX_ID],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        regionsActions.disableRegionSuccess,
        (state): State => ({
            ...state,
            region: {
                ...state.region,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.region[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Region>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        regionsActions.disableRegionFailure,
        (state): State => ({
            ...state,
            region: {
                ...state.region,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.region[GLOBAL_STATE_INDEX_ID],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
