import { Injectable, TemplateRef, inject } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import {
    List,
    ListMember,
    ListType,
    LookupFieldResult,
    Party,
    PartyRole,
    Person,
    Query,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { ActionButtonMode, LIST_FORM_ID } from '@wdx/shared/utils';
import { Observable, Subject, map, take, tap, withLatestFrom } from 'rxjs';
import { ENTITY_ICON_MAP } from '../../../../constants/entity-icons.constants';
import { InfoCardData } from '../../../../models/info-card-data.model';
import { ListTypePipe } from '../../../../pipes/list-type.pipe';
import { ModalManagementService } from '../../../../services/modal-management.service';
import * as listsActions from '../../../../state/lists/lists.actions';
import * as listsSelectors from '../../../../state/lists/lists.selectors';
import { LIST_TYPE_ICON_MAP } from '../constants/lists.static';
import { EDIT_MENU_ITEM } from '../../../../constants/menu.constants';

@Injectable({
    providedIn: 'root',
})
export class ListFacade {
    listId = '';
    standardList = ListType.Standard;

    marketingLists$ = new Subject<List[]>();
    marketingListsIsLoading$: Observable<boolean>;
    marketingListsHasError$: Observable<boolean>;

    list$: Observable<List>;
    listIsLoading$: Observable<boolean>;
    listHasError$: Observable<boolean>;

    members$: Observable<ListMember[]>;
    membersIsLoading$: Observable<boolean>;
    membersHasError$: Observable<boolean>;

    parties$: Observable<Party[]>;
    partiesIsLoading$: Observable<boolean>;
    partiesHasError$: Observable<boolean>;

    partyRoles$: Observable<PartyRole[]>;
    partyRolesIsLoading$: Observable<boolean>;
    partyRolesHasError$: Observable<boolean>;

    people$: Observable<Person[]>;
    peopleIsLoading$: Observable<boolean>;
    peopleHasError$: Observable<boolean>;

    private store$ = inject(Store);
    private actionsSubject$ = inject(ActionsSubject);
    private modalManagementService = inject(ModalManagementService);
    private toastService = inject(WdxToastService);

    loadMarketingLists(): void {
        this.store$.dispatch(listsActions.getLists());
    }

    setMarketingLists(): void {
        this.marketingListsIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingLists
        );
        this.marketingListsHasError$ = this.store$.select(
            listsSelectors.getHasLoadingListsError
        );

        this.store$
            .select(listsSelectors.getListsByType(this.standardList))
            .subscribe((listData) => {
                this.listsAsInfoCardData(listData);
            });
    }

    loadList(listId: string): void {
        this.store$.dispatch(listsActions.getListForId({ listId }));
    }

    setList(listId: string): void {
        this.list$ = this.store$.select(listsSelectors.getListForId(listId));
        this.listIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingListForId,
            { listId }
        );
        this.listHasError$ = this.store$.select(
            listsSelectors.getHasLoadingListForIdError,
            { listId }
        );
    }

    getList(listId): Observable<List> {
        this.listId = listId;
        this.setList(listId);
        return this.list$.pipe(
            withLatestFrom(this.listIsLoading$, this.listHasError$),
            tap(([list, listIsLoading, listHasError]) => {
                if (!list && !listIsLoading && !listHasError) {
                    this.loadList(listId);
                }
            }),
            map(([list]) => list)
        );
    }

    loadParties(listId: string): void {
        this.store$.dispatch(listsActions.getPartiesForId({ listId }));
    }

    loadPartyRoles(listId: string): void {
        this.store$.dispatch(listsActions.getPartyRolesForId({ listId }));
    }

    loadMembers(listId: string): void {
        this.store$.dispatch(listsActions.getMembersForId({ listId }));
    }

    setParties(listId: string): void {
        this.parties$ = this.store$.select(
            listsSelectors.getPartiesForId(listId)
        );
        this.partiesIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingPartiesForId(listId)
        );
        this.partiesHasError$ = this.store$.select(
            listsSelectors.getHasLoadingPartiesForIdError(listId)
        );
    }

    setPartyRoles(listId: string): void {
        this.partyRoles$ = this.store$.select(
            listsSelectors.getPartyRolesForId(listId)
        );
        this.partyRolesIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingPartyRolesForId(listId)
        );
        this.partyRolesHasError$ = this.store$.select(
            listsSelectors.getHasLoadingPartyRolesForIdError(listId)
        );
    }

    setMembers(listId: string): void {
        this.members$ = this.store$.select(
            listsSelectors.getMembersForId(listId)
        );
        this.membersIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingMembersForId(listId)
        );
        this.membersHasError$ = this.store$.select(
            listsSelectors.getHasLoadingMembersForIdError(listId)
        );
    }

    onAddPartiesToList(args: { parties: Party[]; list: List }): void {
        if (!args.parties && !args.list) {
            return;
        }

        this.store$.dispatch(
            listsActions.addPartiesToList({
                list: args.list,
                parties: args.parties,
            })
        );
    }

    onAddLookupResultsToList(args: {
        lookupResults: LookupFieldResult[];
        list: List;
    }): void {
        if (!args.lookupResults && !args.list) {
            return;
        }

        this.store$.dispatch(
            listsActions.addLookupResultsToList({
                list: args.list,
                lookupResults: args.lookupResults,
            })
        );
    }

    onListActionsSuccessful(): Observable<any> {
        return this.actionsSubject$.pipe(
            ofType(
                listsActions.addLookupResultsToListSuccess,
                listsActions.deleteEntityFromListSuccess
            )
        );
    }

    onRemoveMemberFromList(
        entityId: string,
        entityType: SystemEntity,
        listId?: string
    ): void {
        if (!entityId || !entityType) {
            return;
        }

        this.store$.dispatch(
            listsActions.deleteEntityFromList({
                listId: listId || this.listId,
                entityId,
                entityType,
            })
        );
    }

    getMarketingListAsTableRow(
        list: List,
        infoCardTemplate: TemplateRef<any>,
        contextMenuButtonTemplate: TemplateRef<any>,
        entityRouteService: EntityRouteService
    ) {
        const routerLink = entityRouteService.routeForEntity(
            SystemEntity.List,
            list.id
        );

        return {
            routerLink,
            values: [
                {
                    templateRef: infoCardTemplate,
                    data: {
                        label: list.name,
                        avatar: {
                            icon: LIST_TYPE_ICON_MAP[list.type],
                            entityType: SystemEntity.List,
                            entityId: list.id,
                            prioritiseIcon: true,
                        },
                        size: 'md',
                        separator: true,
                        routerLink,
                    },
                },
                {
                    templateRef: infoCardTemplate,
                    data: {
                        label: list.owner.name,
                        avatar: new PartyToAvatarPipe().transform(list.owner),
                        size: 'sm',
                    },
                },
                {
                    value: new ListTypePipe().transform(list.type),
                },
                {
                    value: `${list.numberOfMembers || 0}`,
                },
                {
                    value: list.description,
                },
                {
                    value: list.status,
                },
                {
                    templateRef: contextMenuButtonTemplate,
                    data: {
                        mode: ActionButtonMode.DropdownButtonMenu,
                        dropdownMenu: [
                            {
                                ...EDIT_MENU_ITEM,
                                formSetup: {
                                    formId: LIST_FORM_ID,
                                    entityId: list.id,
                                },
                            },
                        ],
                        operationsSetup: {
                            entityId: list.id,
                            entityType: SystemEntity.List,
                        },
                    },
                },
            ],
        };
    }

    deleteEntityFromList(
        listId: string,
        entityType: SystemEntity,
        entityId: string
    ): void {
        this.store$.dispatch(
            listsActions.deleteEntityFromList({
                listId,
                entityType,
                entityId,
            })
        );
    }

    listsAsInfoCardData(lists: List[]): void {
        const INFO_CARD_DATA: InfoCardData[] = [];

        lists?.map((list) => {
            INFO_CARD_DATA.push({
                id: list.id,
                primaryInfo: list.name,
                secondaryInfo: [{ label: list.description }],
                tertiaryInfo: [
                    { label: `${list.numberOfMembers || 0} members` },
                ],
                icon: ENTITY_ICON_MAP[SystemEntity.List],
                data: list,
            });
        });

        this.marketingLists$.next(INFO_CARD_DATA);
    }

    addToListWithQuery(list: List, entityName: string, query: Query): void {
        this.actionsSubject$
            .pipe(ofType(listsActions.addListResultWithQuerySuccess), take(1))
            .subscribe({
                next: () => {
                    this.modalManagementService.closeActiveModal();
                },
            });

        this.store$.dispatch(
            listsActions.addListResultWithQuery({
                listId: list.id,
                entityName: entityName,
                query: query,
            })
        );
    }

    loadStandardLists(): void {
        this.store$.dispatch(
            listsActions.getListsByType({ listType: this.standardList })
        );
    }
}
