import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    OnInit,
    inject,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { PushPipe } from '@ngrx/component';
import { Answer } from '@wdx/clmi/api-models';
import { REJECT_ANSWERS } from '@wdx/clmi/utils/constants';
import { WdxButtonStyle } from '@wdx/shared/components/wdx-button';
import {
    WdxDialogComponent,
    WdxDialogService,
} from '@wdx/shared/components/wdx-dialog';
import { WdxFormFieldModule } from '@wdx/shared/components/wdx-form-field';
import { WdxInputTextModule } from '@wdx/shared/components/wdx-input-text';
import { WdxMultiSelectComponent } from '@wdx/shared/components/wdx-multi-select';
import { TranslatePipe, WdxDestroyClass } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { AnswersFacadeService } from '../../../state/answers/answers-facade/answers-facade.service';
import { ICommentFormControl } from './work-item-comment.interface';
import { WorkItemDialogService } from './work-item-comment.service';

@Component({
    selector: 'clmi-ui-work-item-comment',
    standalone: true,
    imports: [
        WdxDialogComponent,
        WdxInputTextModule,
        ReactiveFormsModule,
        WdxMultiSelectComponent,
        PushPipe,
        WdxFormFieldModule,
        TranslatePipe,
    ],
    templateUrl: './work-item-comment.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CLMiWorkItemCommentComponent
    extends WdxDestroyClass
    implements OnInit, AfterViewInit
{
    private wdxDialogService = inject(WdxDialogService);
    private workItemDialogService = inject(WorkItemDialogService);
    private answersFacade = inject(AnswersFacadeService);
    private fb = inject(FormBuilder);

    commentForm!: FormGroup<ICommentFormControl>;
    rejectionReasons$: Observable<Answer[]> = this.answersFacade
        .getAnswer$(REJECT_ANSWERS)
        .pipe(
            map((reasons) =>
                reasons.map(({ label }) => ({ label, value: label }))
            )
        );
    readonly WDX_BUTTON_STYLE = WdxButtonStyle;

    ngOnInit(): void {
        this.commentForm = this.fb.group({
            comment: ['', [Validators.required, Validators.minLength(3)]],
            reason: ['', [Validators.required]],
        });

        this.commentForm.valueChanges
            .pipe(debounceTime(150), takeUntil(this.destroyed$))
            .subscribe({
                next: () => {
                    this.workItemDialogService.updateFormValue(
                        this.commentForm.value
                    );
                    this.wdxDialogService.disableButtons$.next(
                        this.commentForm.invalid
                    );
                },
            });
    }

    ngAfterViewInit(): void {
        this.wdxDialogService.disableButtons$.next(true);
    }

    onClick(): void {
        this.workItemDialogService.submitForm();
    }
}
