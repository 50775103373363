<ng-container *ngIf="form">
    <div
        *ngIf="
            !readonlyMode &&
            !auditMode &&
            formStylingOptions?.actionButtonPosition === 'top'
        "
        class="form-action-buttons px-4 py-2 active-component d-flex align-items-center"
        [class.justify-content-center]="formStylingOptions?.hideStatus"
        data-cy="form-framework-action-button-banner-top"
    >
        <ng-container *ngTemplateOutlet="actionButtonBanner"></ng-container>
    </div>

    <div
        *ngIf="!auditMode"
        class="form-container flex-grow-1 overflow-hidden"
        [class.visually-hidden]="showFormHistory"
        [wdxIsLoading]="!!isSaving"
        [loadingOverlay]="true"
    >
        <h6 *ngIf="hasError">{{ SOMETHING_WENT_WRONG | translate }}</h6>
        <div class="h-100 d-flex flex-column overflow-hidden">
            <div *ngIf="form?.invalid && $any(validationSummary?.length) > 0">
                <wdx-ff-validation-summary
                    *ngIf="submitAttempted"
                    class="d-block mb-2"
                    data-cy="lbl-message"
                    [message]="validationMessage"
                    [validationSummary]="validationSummary"
                    (errorClicked)="onErrorClicked($event)"
                >
                    <button
                        *ngIf="saveDraftMode"
                        class="text-nowrap ms-2"
                        data-cy="btn-save-draft"
                        [wdxButton]="WdxButtonStyle.OutlinePrimary"
                        (click)="onDraftSaveClick()"
                    >
                        {{ SAVE_AS_DRAFT_BTN | translate }}
                    </button></wdx-ff-validation-summary
                >
            </div>

            <wdx-ff-pending-changes
                *ngIf="formData?.pendingChanges?.length"
                data-cy="pending-changes"
                [pendingChanges]="$any(formPendingChangesService?.coc)"
                [pendingChangesSubForm]="
                    $any(
                        formPendingChangesService?.pendingChangesSubFormCounter
                    )
                "
            ></wdx-ff-pending-changes>

            <wdx-alert
                *ngIf="messageDefinitionLength"
                class="remove-margin-bottom"
                themeColor="warning"
            >
                <wdx-icon
                    class="pe-3 align-self-start"
                    icon="info-circle"
                ></wdx-icon>
                <span
                    *ngFor="
                        let messageDefinition of messageDefinition | keyvalue;
                        trackBy: trackByMessage
                    "
                    [innerHTML]="
                        $any(messageDefinition?.value)?.label
                            | translateTokenisedString
                            | markdownToHtml
                            | safeHtml
                    "
                ></span>
            </wdx-alert>

            <wdx-ff-version-info *ngIf="showFormSwitcherPanel">
                <ng-container
                    *ngTemplateOutlet="formVersionInfo"
                ></ng-container>
            </wdx-ff-version-info>

            <div *ngIf="formData?.lock">
                <wdx-ff-lock-summary
                    [formData]="formData"
                ></wdx-ff-lock-summary>
            </div>

            <ng-container
                *ngIf="summaryInfoCards$ | ngrxPush; let summaryInfoCards"
            >
                <div *ngIf="summaryInfoCards.length" class="border-bottom">
                    <wdx-ff-summary-info-cards
                        [infoCards]="summaryInfoCards"
                    ></wdx-ff-summary-info-cards>
                </div>
            </ng-container>

            <form
                class="form flex-grow-1 overflow-hidden"
                autocomplete="off"
                [formGroup]="form"
                data-cy="ff-form"
                (click)="formElementClicked($event)"
            >
                <div class="d-block h-100 overflow-auto scroll-smooth">
                    <div
                        ngbAccordion
                        #accordion
                        class="accordion form-accordion accordion-flush"
                        [destroyOnHide]="false"
                        [closeOthers]="false"
                    >
                        <ng-container
                            *ngFor="
                                let formSectionLayoutDefinition of condService.layoutAndDefinition;
                                let i = index;
                                let first = first;
                                trackBy: trackSection
                            "
                        >
                            <ng-container
                                *ngIf="
                                    !formSectionLayoutDefinition?.section
                                        ?.isHidden
                                "
                            >
                                <div
                                    [ngbAccordionItem]="'reactive-form-' + i"
                                    [ngClass]="
                                        'form-section border-bottom-0' +
                                        (formStylingOptions?.background ===
                                        'muted'
                                            ? ' bg-muted'
                                            : '') +
                                        (first ? '' : ' mt-1') +
                                        (condService.layoutAndDefinition
                                            .length > 1
                                            ? ''
                                            : ' no-panel-toggle')
                                    "
                                    [collapsed]="
                                        !activePanelIds.includes(
                                            'reactive-form-' + i
                                        )
                                    "
                                >
                                    <div ngbAccordionHeader>
                                        <button
                                            *ngIf="
                                                condService.layoutAndDefinition
                                                    .length > 1
                                            "
                                            ngbAccordionButton
                                            class="form-section__header accordion-header accordion-button d-flex align-items-center bg-muted"
                                            [attr.data-cy]="
                                                'panel-toggle-' +
                                                formSectionLayoutDefinition
                                                    .section?.name
                                            "
                                        >
                                            <div
                                                class="d-flex align-items-center flex-grow-1"
                                            >
                                                <h6>
                                                    {{
                                                        formSectionLayoutDefinition
                                                            .section?.label
                                                            | translateTokenisedString
                                                    }}
                                                </h6>

                                                <wdx-icon
                                                    *ngIf="
                                                        submitAttempted &&
                                                        sectionsInError?.includes(
                                                            $any(
                                                                formSectionLayoutDefinition
                                                                    .section
                                                                    ?.name
                                                            )
                                                        )
                                                    "
                                                    class="text-danger ms-2"
                                                    icon="exclamation-triangle"
                                                >
                                                </wdx-icon>
                                            </div>
                                        </button>
                                    </div>

                                    <div ngbAccordionCollapse>
                                        <div
                                            ngbAccordionBody
                                            class="form-section__body"
                                        >
                                            <h6
                                                *ngIf="
                                                    formSectionLayoutDefinition
                                                        .section?.guidanceText
                                                "
                                                class="form-section__body__blurb small"
                                            >
                                                {{
                                                    formSectionLayoutDefinition
                                                        .section?.guidanceText
                                                        | translateTokenisedString
                                                }}
                                            </h6>

                                            <div
                                                class="form-section__body__fields form__fields grid"
                                            >
                                                <ng-container
                                                    *ngFor="
                                                        let elementDefinition of formSectionLayoutDefinition.layoutAndDefinition;
                                                        let iLayout = index;
                                                        trackBy: trackElement
                                                    "
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            !elementDefinition.isHidden &&
                                                            (elementDefinition.elementType ||
                                                                elementDefinition.fieldType)
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                elementDefinition.forcesNewRow &&
                                                                !elementDefinition.forcesFullWidth
                                                            "
                                                            class="g-col-12"
                                                        ></div>

                                                        <div
                                                            class="g-col-12 input form-input"
                                                            [class.g-col-lg-6]="
                                                                !elementDefinition.forcesFullWidth &&
                                                                elementDefinition.fieldType !==
                                                                    FORM_FIELD_TYPE.Address &&
                                                                (!elementDefinition.forcesNewRow ||
                                                                    !elementDefinition.forcesFullWidth)
                                                            "
                                                            [attr.data-cy]="
                                                                'lbl-' +
                                                                elementDefinition.name
                                                            "
                                                            [attr.data-form-control]="
                                                                elementDefinition.name
                                                            "
                                                        >
                                                            <ng-container
                                                                *ngIf="
                                                                    ARRAY_CONTROL_TYPES.includes(
                                                                        $any(
                                                                            elementDefinition?.fieldType
                                                                        )
                                                                    );
                                                                    else standardControls
                                                                "
                                                            >
                                                                <h6
                                                                    *ngIf="
                                                                        elementDefinition
                                                                            ?.children?.[0]
                                                                            ?.section
                                                                            ?.guidanceText
                                                                    "
                                                                    class="form-section__body__blurb small"
                                                                >
                                                                    {{
                                                                        elementDefinition
                                                                            ?.children?.[0]
                                                                            ?.section
                                                                            ?.guidanceText
                                                                    }}
                                                                </h6>
                                                                <wdx-ff-form-array
                                                                    [entityId]="
                                                                        entityId
                                                                    "
                                                                    [formArrayName]="
                                                                        $any(
                                                                            elementDefinition.name
                                                                        )
                                                                    "
                                                                    [elementDefinition]="
                                                                        elementDefinition
                                                                    "
                                                                    [fbService]="
                                                                        fbService
                                                                    "
                                                                    [formContext]="
                                                                        formData?.context
                                                                    "
                                                                    [parentData]="
                                                                        formData.data
                                                                    "
                                                                ></wdx-ff-form-array>
                                                            </ng-container>

                                                            <ng-template
                                                                #standardControls
                                                            >
                                                                <wdx-ff-controls-container
                                                                    [entityId]="
                                                                        entityId
                                                                    "
                                                                    [formElement]="
                                                                        elementDefinition
                                                                    "
                                                                    [formContext]="
                                                                        formData?.context
                                                                    "
                                                                ></wdx-ff-controls-container>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div
                        *ngIf="
                            !readonlyMode &&
                            formStylingOptions?.actionButtonPosition ===
                                'bottom'
                        "
                        class="form-action-buttons px-4 py-2 d-flex align-items-center"
                        [class.justify-content-center]="
                            formStylingOptions?.hideStatus
                        "
                        data-cy="form-framework-action-button-banner-bottom"
                    >
                        <ng-container
                            *ngTemplateOutlet="actionButtonBanner"
                        ></ng-container>
                    </div>
                </div>
            </form>

            <ng-template #formVersionInfo>
                <div>
                    {{ FORM_INFO_YOU_ARE_VIEWING | translate }}
                    <span class="fw-bold">{{ formData.status }}</span>
                    {{ VERSION_OF_THIS_FORM | translate }} ({{
                        BY | translate
                    }}
                    <button
                        [wdxButton]="WdxButtonStyle.Link"
                        (click)="
                            openLinkInNewTab(
                                $any(
                                    formData?.context?.lastUpdatedBy?.partyType
                                ),
                                $any(formData?.context?.lastUpdatedBy?.id)
                            )
                        "
                    >
                        {{ formData?.lastUpdatedBy || 'unknown user' }}</button
                    >,
                    {{
                        $any(formData?.lastUpdatedOn)
                            | displayDate: { relative: true }
                    }}) -
                    <button
                        [wdxButton]="WdxButtonStyle.Link"
                        (click)="switchFormVersion()"
                    >
                        {{ CLICK_HERE_BTN | translate }}
                        {{
                            formData.status === FORM_DATA_STATUS.Draft
                                ? 'previously saved'
                                : 'draft'
                        }}
                        {{ CLICK_TO_VIEW_VERSION_BTN | translate }}
                    </button>
                </div>
            </ng-template>
        </div>
    </div>

    <wdx-ff-form-history
        *ngIf="showFormHistory || auditMode"
        class="flex-grow-1 overflow-hidden"
        [title]="formLayout?.label | translateTokenisedString"
        [formId]="formId"
        [entityId]="entityId"
    ></wdx-ff-form-history>
</ng-container>

<ng-template #actionButtonBanner>
    <div
        *ngIf="!formStylingOptions?.hideStatus && entityId && appId"
        class="fw-bold me-2"
        data-cy="form-completion-info"
    >
        <ng-container
            *ngIf="completionSummary?.expected; else noRequiredFields"
        >
            {{ completionSummary?.completed }} {{ OF_LABEL | translate }}
            {{ completionSummary?.expected }} {{ FIELDS_COMPLETED | translate }}
        </ng-container>

        <ng-template #noRequiredFields>{{
            NO_REQUIRED_FIELDS | translate
        }}</ng-template>
    </div>
    <div
        class="d-flex align-items-center"
        [class.ms-auto]="!formStylingOptions?.hideStatus"
    >
        <wdx-badge
            *ngIf="
                !formStylingOptions?.hideStatus &&
                saveDraftMode &&
                (isDraftForm || isPublishedForm)
            "
            [themeColor]="
                $any((isDraftForm && 'info') || (isPublishedForm && 'success'))
            "
            data-cy="form-status"
        >
            {{ formDataStatus | translate }}
        </wdx-badge>

        <button
            *ngIf="isPublishedForm"
            class="ms-2"
            wdxIconButton
            [wdxButton]="WdxButtonStyle.None"
            [ngbPopover]="
                (showFormHistory ? POPOVER_BACK_TO_FORM : POPOVER_FORM_HISTORY)
                    | translate
            "
            [triggers]="POP_UP_TRIGGER.triggers"
            [openDelay]="POP_UP_TRIGGER.openDelay"
            [closeDelay]="POP_UP_TRIGGER.closeDelay"
            (click)="toggleFormHistory()"
        >
            <wdx-icon
                [icon]="showFormHistory ? ICON_BACK : ICON_FORM_HISTORY"
            ></wdx-icon>
        </button>

        <ng-container
            *ngIf="
                formData?.actions
                    | formActions
                        : formStylingOptions?.hideCancelButton as formActionButtons
            "
        >
            <ng-container *ngFor="let formActionButton of formActionButtons">
                <ng-container
                    *ngIf="formActionButton.dropdown?.length; else btnTmpl"
                >
                    <wdx-split-button
                        class="ms-2"
                        data-cy="form-split-action-button"
                        [isLoading]="isSaving"
                        [disabled]="
                            formActionButton.type !== 'Cancel' &&
                            (isSaving || fullFormLock)
                        "
                        [menuItems]="formActionButton.dropdown || []"
                        (menuItemClicked)="onFormAction($event)"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                btnTmpl;
                                context: { split: true }
                            "
                        ></ng-container>
                    </wdx-split-button>
                </ng-container>

                <ng-template #btnTmpl let-split="split">
                    <button
                        [class.ms-2]="!split"
                        [attr.splitbutton]="split"
                        [attr.data-cy]="formActionButton.dataCy"
                        [wdxButton]="formActionButton.buttonStyle"
                        [disabled]="
                            formActionButton.type !== 'Cancel' &&
                            (isSaving || fullFormLock)
                        "
                        (click)="onFormAction(formActionButton)"
                    >
                        <wdx-spinner
                            *ngIf="
                                formActionButton.type !== 'Cancel' && isSaving
                            "
                            [small]="true"
                        ></wdx-spinner>
                        {{ formActionButton.label }}
                    </button>
                </ng-template>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
