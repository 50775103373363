import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    FilterFieldDefinition,
    Query,
    QueryPaging,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { QueryType, SelectType } from '@wdx/clmi/api-services/models';
import { Paging } from '@wdx/shared/utils';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import * as filterActions from '../actions';
import { FILTER_FEATURE_KEY, FilterState } from '../reducer';
import * as filterSelectors from '../selector';
import { PaginationOptions } from '@wdx/shared/infrastructure/api-service';

@Injectable({
    providedIn: 'root',
})
export class FilterQueryFacadeService {
    resetResults$ = new Subject();

    private store$ = inject(Store<{ [FILTER_FEATURE_KEY]: FilterState }>);

    getFilterDefinition$(
        queryType: QueryType
    ): Observable<FilterFieldDefinition[]> {
        return this.store$
            .select(
                filterSelectors.getFilterDefinition,
                queryType as SystemEntity
            )
            .pipe(
                tap((res) => {
                    if (!res) {
                        this.getDefinitions(queryType);
                    }
                }),
                filter(Boolean),
                take(1)
            ) as Observable<FilterFieldDefinition[]>;
    }

    getResults(
        queryType: QueryType,
        filters: Query,
        paginationOptions: PaginationOptions = {},
        multipleTypes = false,
        selectType?: SelectType
    ): void {
        if (!multipleTypes) {
            this.store$.dispatch(
                filterActions.getFilterResults({
                    queryType,
                    paginationOptions,
                    filters,
                    selectType: selectType || queryType,
                })
            );
        }

        if (multipleTypes) {
            this.store$.dispatch(
                filterActions.getFilterResultsMergeMap({
                    queryType,
                    paginationOptions,
                    filters,
                    selectType: selectType || queryType,
                })
            );
        }
    }

    getDefinitions(queryType: QueryType): void {
        this.store$.dispatch(
            filterActions.getFilterDefinition({
                queryType,
            })
        );
    }

    /**
     * This will dispatch the filterActions updateFilterResults
     *
     * @param queryType | TypeSearch
     * @param paging | QueryPaging
     * @param query | Query
     */
    updateResults(
        queryType: QueryType,
        paging: QueryPaging,
        query: Query,
        selectType?: SelectType
    ): void {
        if ((paging?.page || 1) <= (paging?.totalPages || 1)) {
            this.store$.dispatch(
                filterActions.updateFilterResults({
                    queryType,
                    paginationOptions: {
                        ...paging,
                        pageNumber: paging.page,
                    },
                    filters: query,
                    selectType: selectType || queryType,
                })
            );
        }
    }

    resetResults(queryType: QueryType, selectType?: SelectType): void {
        this.resetResults$.next(true);
        this.store$.dispatch(
            filterActions.resetFilterResults({
                selectType: selectType || queryType,
            })
        );
    }

    /**
     * Use this method to reset the results and fetch it again
     *
     * @param filterType: FilterType
     * @param query: Query
     */
    resetResultsGetRecords(
        queryType: QueryType,
        query: Query,
        paging?: PaginationOptions,
        multipleTypes = false,
        selectType?: SelectType
    ): void {
        this.resetResults(queryType, selectType);
        this.getResults(queryType, query, paging, multipleTypes, selectType);
    }

    selectFirstResults$(selectType: SelectType): Observable<any> {
        return this.store$.select(
            filterSelectors.selectFirstResults,
            selectType as SystemEntity
        );
    }

    selectFilterLoading$(selectType: SelectType): Observable<boolean> {
        return this.store$
            .select(
                filterSelectors.selectFilterLoading,
                selectType as SystemEntity
            )
            .pipe(map(Boolean));
    }

    selectFilterError$(selectType: SelectType): Observable<boolean> {
        return this.store$
            .select(
                filterSelectors.selectFilterError,
                selectType as SystemEntity
            )
            .pipe(map(Boolean));
    }

    selectFilterResultsSuccess$(selectType: SelectType): Observable<any> {
        return this.store$.select(
            filterSelectors.selectFilterResultsSuccess,
            selectType as SystemEntity
        );
    }

    getFilterPaging$(selectType: SelectType): Observable<Paging | null> {
        return this.store$.select(
            filterSelectors.getFilterPaging,
            selectType as SystemEntity
        );
    }
}
