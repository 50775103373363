<ng-container
    [formGroup]="$any(controlContainer.control)"
    *ngIf="
        $any(
            fbService?.firstLevel?.[$any(elementDefinition?.name)]
        ) as FIRST_LEVEL_ELEMENT
    "
>
    <wdx-ff-sub-form-header
        [amount]="FIRST_LEVEL_ELEMENT.formArray.controls.length"
        [elementDefinition]="$any(elementDefinition)"
        [disabled]="FIRST_LEVEL_ELEMENT.formArray.disabled"
        [showValidation]="validationStatus"
        (addToArray)="addToArray(elementDefinition)"
    ></wdx-ff-sub-form-header>
    <hr />

    <div
        ngbAccordion
        #accordionSubFrom
        class="accordion sub-form-accordion d-block"
        *ngIf="fbService?.firstLevel"
        [closeOthers]="false"
        [destroyOnHide]="false"
        (hide)="panelChange($event, true)"
        (show)="panelChange($event, false)"
    >
        <ng-container
            *ngFor="
                let childForm of FIRST_LEVEL_ELEMENT.formArray.controls;
                let childFormIndex = index;
                let first = first
            "
        >
            <div
                *ngIf="FIRST_LEVEL_ELEMENT?.pendingChanges as PENDING_CHANGES"
                [ngbAccordionItem]="
                    'ngb-panel-' +
                    elementDefinition.name +
                    '-child-' +
                    childFormIndex
                "
                tabindex="0"
                [attr.data-cy]="elementDefinition.name + '-' + childFormIndex"
                [class.danger]="PENDING_CHANGES[childFormIndex]"
                class="card-muted sub-form"
            >
                <div ngbAccordionHeader>
                    <button
                        ngbAccordionButton
                        #panelToggle
                        type="button"
                        class="accordion-header accordion-button w-100 bg-muted"
                        data-cy="organism-reactive-accordion-header"
                    >
                        <wdx-ff-accordion-header
                            class="flex-grow-1"
                            [entityId]="entityId"
                            [place]="childFormIndex"
                            [elementDefinition]="elementDefinition"
                            [parentData]="parentData"
                            [formControlObj]="childForm"
                            [panelInfo]="openPanels"
                            [hasError]="!!validationSummary[childFormIndex]"
                            [disabled]="childForm.disabled"
                            [status]="PENDING_CHANGES[childFormIndex]?.action"
                            (removeItem)="
                                removeFromArray(
                                    elementDefinition,
                                    childFormIndex
                                )
                            "
                        ></wdx-ff-accordion-header>
                    </button>
                </div>

                <div ngbAccordionCollapse>
                    <wdx-ff-sub-form-content
                        ngbAccordionBody
                        #subForm
                        class="d-block"
                        [formGroupName]="childFormIndex"
                        [attr.data-cy]="
                            elementDefinition.name + '-' + childFormIndex
                        "
                    >
                        <div
                            *ngIf="
                                validationSummary[childFormIndex];
                                let validationSummary
                            "
                            class="g-col-12"
                        >
                            <wdx-ff-validation-summary
                                *ngIf="
                                    $any(validationSummary?.errors?.length) > 0
                                "
                                [message]="VALIDATION_SUMMARY_MESSAGE"
                                [validationSummary]="validationSummary"
                                (errorClicked)="
                                    onErrorClicked($event, childFormIndex)
                                "
                            ></wdx-ff-validation-summary>
                        </div>

                        <ng-container
                            *ngFor="
                                let childElement of $any(
                                    elementDefinition.children?.[0]
                                        ?.layoutAndDefinition?.[childFormIndex]
                                );
                                let childElementIndex = index;
                                trackBy: trackSubFormElement
                            "
                        >
                            <ng-container
                                *ngIf="
                                    !childElement.isHidden &&
                                    (childElement.elementType ||
                                        childElement.fieldType)
                                "
                            >
                                <div
                                    class="g-col-12"
                                    *ngIf="
                                        childElement.forcesNewRow &&
                                        !childElement.forcesFullWidth
                                    "
                                ></div>

                                <div
                                    class="g-col-12 input form-input"
                                    [attr.data-cy]="'lbl-' + childElement.name"
                                    [attr.data-form-control]="childElement.name"
                                    [class.g-col-lg-6]="
                                        !childElement.forcesFullWidth &&
                                        childElement.fieldType !==
                                            FORM_FIELD_TYPE.Address &&
                                        (!childElement.forcesNewRow ||
                                            !childElement.forcesFullWidth)
                                    "
                                >
                                    <wdx-ff-controls-container
                                        *ngIf="
                                            childElement.fieldType !==
                                            FORM_FIELD_TYPE.Array
                                        "
                                        [entityId]="entityId"
                                        [formElement]="
                                            $any(
                                                childElement
                                                    | spread
                                                        : {
                                                              parentName:
                                                                  elementDefinition.name,
                                                              index: childFormIndex,
                                                          }
                                            )
                                        "
                                        [formContext]="formContext"
                                    ></wdx-ff-controls-container>
                                </div>

                                <div
                                    class="g-col-12 input form-input"
                                    *ngIf="childElement.fieldType === 'Array'"
                                    [formArrayName]="childElement.name"
                                >
                                    <div
                                        class="form-section__body__blurb sub-3"
                                        *ngIf="
                                            childElement?.children[0]?.section
                                                ?.guidanceText
                                        "
                                    >
                                        {{
                                            childElement?.children[0]?.section
                                                ?.guidanceText
                                        }}
                                    </div>
                                    <wdx-ff-sub-form-header
                                        [amount]="
                                            fbService.firstLevel[
                                                $any(elementDefinition.name)
                                            ].formArray.controls[childFormIndex]
                                                .controls[childElement.name]
                                                .controls.length
                                        "
                                        [elementDefinition]="childElement"
                                        (addToArray)="
                                            fbService?.addToArray(
                                                childElement,
                                                $any(elementDefinition),
                                                childFormIndex
                                            )
                                        "
                                    ></wdx-ff-sub-form-header>

                                    <div
                                        ngbAccordion
                                        class="accordion sub-form-accordion d-block"
                                        [closeOthers]="false"
                                        [destroyOnHide]="false"
                                        [animation]="false"
                                        (hide)="panelChildChange($event, true)"
                                        (show)="panelChildChange($event, false)"
                                    >
                                        <div
                                            ngbAccordionItem
                                            class="sub-form"
                                            id="ngb-panel-child-{{
                                                childElement.name
                                            }}-grandchild-{{
                                                grandChildFormIndex
                                            }}"
                                            *ngFor="
                                                let grandChildForm of fbService
                                                    .firstLevel[
                                                    $any(elementDefinition.name)
                                                ].formArray.controls[
                                                    childFormIndex
                                                ].controls[childElement.name]
                                                    .controls;
                                                let grandChildFormIndex = index
                                            "
                                        >
                                            <div ngbAccordionHeader>
                                                <button
                                                    ngbAccordionButton
                                                    type="button"
                                                    class="accordion-header d-block w-100"
                                                >
                                                    <wdx-ff-accordion-header
                                                        [entityId]="entityId"
                                                        [place]="
                                                            grandChildFormIndex
                                                        "
                                                        [elementDefinition]="
                                                            childElement
                                                        "
                                                        [formControlObj]="
                                                            grandChildForm
                                                        "
                                                        [panelInfo]="
                                                            openChildPanels
                                                        "
                                                        [hasError]="
                                                            $any(
                                                                validationSummary?.[
                                                                    childFormIndex
                                                                ]
                                                                    ?.subsections?.[
                                                                    grandChildFormIndex
                                                                ]
                                                            )
                                                        "
                                                        (removeItem)="
                                                            fbService.removeFromArray(
                                                                childElement,
                                                                grandChildFormIndex,
                                                                elementDefinition,
                                                                childFormIndex
                                                            )
                                                        "
                                                    ></wdx-ff-accordion-header>
                                                </button>
                                            </div>

                                            <div ngbAccordionCollapse>
                                                <wdx-ff-sub-form-content
                                                    ngbAccordionBody
                                                    #grandChildForm
                                                    class="d-block accordion-body"
                                                    [formGroupName]="
                                                        grandChildFormIndex
                                                    "
                                                >
                                                    <wdx-ff-validation-summary
                                                        *ngIf="
                                                            $any(
                                                                validationSummary?.[
                                                                    childFormIndex
                                                                ]
                                                                    ?.subsections?.[
                                                                    grandChildFormIndex
                                                                ]
                                                            )
                                                        "
                                                        [message]="
                                                            VALIDATION_SUMMARY_MESSAGE
                                                        "
                                                        [validationSummary]="
                                                            $any(
                                                                validationSummary?.[
                                                                    childFormIndex
                                                                ]
                                                                    ?.subsections?.[
                                                                    grandChildFormIndex
                                                                ]
                                                            )
                                                        "
                                                        (errorClicked)="
                                                            onErrorClicked(
                                                                $event,
                                                                childFormIndex,
                                                                grandChildFormIndex
                                                            )
                                                        "
                                                    ></wdx-ff-validation-summary>

                                                    <ng-container
                                                        *ngFor="
                                                            let granChildElement of childElement
                                                                .children[0]
                                                                .layoutAndDefinition;
                                                            let grandChildElementIndex = index
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="
                                                                !granChildElement.isHidden &&
                                                                (granChildElement.elementType ||
                                                                    granChildElement.fieldType)
                                                            "
                                                        >
                                                            <div
                                                                class="g-col-12"
                                                                *ngIf="
                                                                    granChildElement.forcesNewRow &&
                                                                    !granChildElement.forcesFullWidth
                                                                "
                                                            ></div>

                                                            <div
                                                                class="g-col-12 input form-input"
                                                                [class.g-col-lg-6]="
                                                                    !granChildElement.forcesFullWidth &&
                                                                    granChildElement.fieldType !==
                                                                        FORM_FIELD_TYPE.Address &&
                                                                    (!granChildElement.forcesNewRow ||
                                                                        !granChildElement.forcesFullWidth)
                                                                "
                                                                [attr.data-cy]="
                                                                    'lbl-' +
                                                                    granChildElement.name
                                                                "
                                                                [attr.data-form-control]="
                                                                    granChildElement.name
                                                                "
                                                            >
                                                                <wdx-ff-controls-container
                                                                    *ngIf="
                                                                        granChildElement.fieldType !==
                                                                        FORM_FIELD_TYPE.Array
                                                                    "
                                                                    [entityId]="
                                                                        entityId
                                                                    "
                                                                    [formElement]="
                                                                        granChildElement
                                                                    "
                                                                    [formContext]="
                                                                        formContext
                                                                    "
                                                                ></wdx-ff-controls-container>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </wdx-ff-sub-form-content>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </wdx-ff-sub-form-content>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
