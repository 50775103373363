import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { DocumentType } from '@wdx/clmi/api-models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as documentTypesSelectors from '../../state/document-types/document-types.selectors';
import * as rootReducer from './../../state/_setup/reducers';
import * as documentTypesActions from './document-types.actions';
import { DocumentTypesApiService } from './document-types.service';

@Injectable()
export class DocumentTypesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private documentTypesApiService = inject(DocumentTypesApiService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTypesActions.getAll),
            withLatestFrom(
                this.store$.select(
                    documentTypesSelectors.getDocumentTypesInfinityPaging({
                        id: GLOBAL_STATE_INDEX_ID,
                    })
                )
            ),
            switchMap(([action, paging]) =>
                this.documentTypesApiService
                    .getAll({
                        pageNumber: action.reset ? 1 : (paging?.page || 0) + 1,
                    })
                    .pipe(
                        map((documentTypes) => {
                            return documentTypesActions.getAllSuccess({
                                documentTypes,
                            });
                        }),
                        catchError((error) =>
                            of(
                                documentTypesActions.getAllFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateDocumentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTypesActions.updateDocumentTypes),
            switchMap((action) =>
                this.documentTypesApiService
                    .updateDocumentTypes(action.paginationOptions)
                    .pipe(
                        map((payload) =>
                            documentTypesActions.updateDocumentTypesSuccess({
                                payload,
                            })
                        ),
                        catchError((error) =>
                            of(
                                documentTypesActions.updateDocumentTypesFailure(
                                    {
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    queryDocumentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTypesActions.queryDocumentTypes),
            switchMap((action) =>
                this.documentTypesApiService
                    .queryDocumentTypes(action.query)
                    .pipe(
                        map((documentTypes) =>
                            documentTypesActions.queryDocumentTypesSuccess({
                                documentTypes,
                            })
                        ),
                        catchError((error) =>
                            of(
                                documentTypesActions.queryDocumentTypesFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    disableDocumentType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTypesActions.disableDocumentType),
            switchMap((action) =>
                this.documentTypesApiService
                    .disableDocumentType(action.code, action.isDisabled)
                    .pipe(
                        map((updatedDocType: DocumentType) =>
                            documentTypesActions.disableDocumentTypeSuccess({
                                code: action.code,
                                isDisabled: action.isDisabled,
                                updatedDocType,
                            })
                        ),
                        catchError(() =>
                            of(
                                documentTypesActions.disableDocumentTypeFailure(
                                    {
                                        code: action.code,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteDocumentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTypesActions.deleteDocumentTypes),
            mergeMap((action) =>
                this.documentTypesApiService
                    .deleteDocumentType(action.code)
                    .pipe(
                        map(() =>
                            documentTypesActions.deleteDocumentTypesSuccess({
                                code: action.code,
                            })
                        ),
                        catchError((error) =>
                            of(
                                documentTypesActions.deleteDocumentTypesFailure(
                                    {
                                        code: action.code,
                                        error: error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getDocumentTypeById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTypesActions.getDocumentTypeById),
            mergeMap((action) =>
                this.documentTypesApiService
                    .getDocumentTypeDetail(action.documentTypeCode)
                    .pipe(
                        map((document) =>
                            documentTypesActions.getDocumentTypeByIdSuccess({
                                document,
                                documentTypeCode: action.documentTypeCode,
                            })
                        ),
                        catchError((error) =>
                            of(
                                documentTypesActions.getDocumentTypeByIdFailure(
                                    {
                                        documentTypeCode:
                                            action.documentTypeCode,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getAllActiveDocumentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTypesActions.getAllActiveDocumentTypes),
            switchMap(() =>
                this.getActiveDocumentTypesNextPage().pipe(
                    map((activeDocumentTypes) =>
                        documentTypesActions.getAllActiveDocumentTypesSuccess({
                            activeDocumentTypes,
                        })
                    )
                )
            ),
            catchError((error) =>
                of(
                    documentTypesActions.getAllActiveDocumentTypesFailure({
                        error,
                    })
                )
            )
        )
    );

    getActiveDocumentTypesNextPage(
        page = 1,
        activeDocumentTypes: DocumentType[] = []
    ): Observable<DocumentType[]> {
        return this.documentTypesApiService.getActiveDocumentTypes(page).pipe(
            switchMap((result) => {
                const allDocTypes: DocumentType[] = [
                    ...activeDocumentTypes,
                    ...result.results,
                ];
                if (result.paging.totalPages > result.paging.page) {
                    return this.getActiveDocumentTypesNextPage(
                        result.paging.page + 1,
                        allDocTypes
                    );
                }
                return of(allDocTypes);
            })
        );
    }
}
