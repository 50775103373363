<div
    class="avatar avatar-{{ size }}"
    [class.bg-icon]="avatar?.prioritiseIcon"
    [ngClass]="avatar?.isUnknown ? 'bg-danger text-danger-contrast' : ''"
    [attr.data-cy]="'avatar-' + avatar?.text"
    [style.backgroundColor]="
        !avatar?.isUnknown && !avatar?.icon && !avatar?.avatarUrl
            ? (avatar?.text | textToHsl)
            : null
    "
    [style.color]="
        (!avatar?.isUnknown && avatar?.text) || avatar?.prioritiseIcon
            ? 'var(--bs-body-color)'
            : null
    "
>
    <div
        *ngIf="highlightBorder"
        class="highlight-border highlight-border--rounded highlight-border--{{
            highlightBorder
        }}"
    ></div>

    <span
        *ngIf="
            !avatar?.prioritiseIcon && avatar?.text && !avatar?.isUnknown;
            else altIcon
        "
        class="avatar-alt-initials"
        >{{
            avatar?.text
                ? (avatar?.text | fn : getInitials : avatar?.text)
                : null
        }}</span
    >

    <ng-template #altIcon>
        <wdx-icon-container [size]="size">
            <wdx-icon
                class="avatar-alt-icon"
                [class.text-gray-500]="!avatar?.isUnknown"
                [icon]="avatar | fn : getAvatarIcon"
            ></wdx-icon>
        </wdx-icon-container>
    </ng-template>

    <ng-template #altText>
        <span class="avatar-alt-text">{{ avatar?.text }}</span>
    </ng-template>

    <div
        class="avatar-image"
        [style.background-image]="
            avatar?.avatarUrl ? 'url(' + avatar?.avatarUrl + ')' : ''
        "
    ></div>
</div>

<atom-icon-button
    *ngIf="editModalId"
    class="position-absolute bottom-0 end-0"
    size="sm"
    icon="pencil"
    [modalId]="editModalId"
></atom-icon-button>
