import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
    FormCompletionSummary,
    FormDataResult,
    FormDefinition,
    FormLayout,
    FormStateData,
    FormTemplateInstance,
    KeyValueObject,
    SystemEntity,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { Observable, Subject, combineLatest, forkJoin, iif, of } from 'rxjs';
import {
    filter,
    map,
    switchMap,
    switchMapTo,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    FormFrameworkEvent,
    FormFrameworkLoadedEvent,
    FormFrameworkSaveFailedEvent,
    FormFrameworkSaveStartEvent,
    FormFrameworkSaveSuccessEvent,
    FormStylingOptions,
} from '../../models';

import {
    DynamicFormsService,
    dynamicFormsActions,
    dynamicFormsReducers,
    dynamicFormsSelectors,
} from '../../+state/dynamic-forms';
import { DEFAULT_FORM_STYLING } from '../../constants';
import { IFormExternalActions } from '../../interfaces';
import { FormPresentationService } from '../../services';

@Component({
    selector: 'wdx-ff-form-container',
    templateUrl: './form-framework-form-container.component.html',
    providers: [FormPresentationService],
})
export class FormFrameworkFormContainerComponent
    extends WdxDestroyClass
    implements OnInit
{
    public formPresentationService = inject(FormPresentationService);
    private actions$ = inject(Actions);
    private store$ = inject(
        Store<{
            [dynamicFormsReducers.FEATURE_KEY]: dynamicFormsReducers.State;
        }>,
    );
    private dynamicFormsService = inject(DynamicFormsService);
    private externalActionsHandler = inject(IFormExternalActions);
    private cdr = inject(ChangeDetectorRef);

    @Input() formTemplateId!: string;
    @Input() formTemplateEntityId!: string;
    @Input() formTemplateEntityType!: SystemEntity;
    @Input() formId!: string;
    @Input() entityId!: string;
    @Input() appId!: string;
    @Input() customEndpoint!: string;
    @Input() isQuickCreate = false;
    @Input() initialisationParams!: KeyValueObject;
    @Input() queryParams!: KeyValueObject;
    @Input() emitInsteadOfPost!: boolean;
    @Input() emitData!: any;
    @Input() modalInstanceId?: string;
    @Input() set stylingOptions(options: FormStylingOptions) {
        this.formStylingOptions = {
            ...DEFAULT_FORM_STYLING,
            ...options,
        };
    }
    @Input() readonlyMode = false;
    @Input() auditMode = false;
    @Input() extraContext?: Record<string, any>;

    @Output() formTitleReceived = new EventEmitter<string>();
    @Output() formEvent = new EventEmitter<FormFrameworkEvent>();

    formDefinition!: FormDefinition;

    formLayout!: FormLayout;

    formData!: FormDataResult;
    formData$!: Observable<FormDataResult>;
    formDataIsLoading$!: Observable<boolean>;
    formDataIsSaving$!: Observable<boolean>;
    formDataHasError$!: Observable<boolean>;
    formStylingOptions!: FormStylingOptions;
    triggerSave?: number;

    getFormTemplateId$ = new Subject();
    getFormTemplateData!: any;

    getAllFormInfo$ = this.formPresentationService.formDefinition$.pipe(
        filter((formDefinition) => Boolean(formDefinition)),
        tap((formDefinition) => this.loadFormData(formDefinition)),
        switchMap((_) => {
            const CONDITIONAL_GET_DATA = this.getFormTemplateData
                ? of(this.getFormTemplateData)
                : this.formData$.pipe(
                      filter((res) => Boolean(res)),
                      take(1),
                  );

            return forkJoin([
                this.formPresentationService.formDefinition$,
                CONDITIONAL_GET_DATA,
            ]);
        }),
    );

    isLoadingData = true;

    ngOnInit(): void {
        this.listenTo(
            ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions,
        ).subscribe((action: any) => {
            if (this.formId === action?.formId) {
                this.formEvent.emit(new FormFrameworkSaveSuccessEvent());
                this.externalActionsHandler.closeSpawnedForms(
                    new FormFrameworkSaveSuccessEvent(),
                );
                this.store$.dispatch(
                    dynamicFormsActions.setFormData({
                        formId: action?.formId,
                        entityId: action?.response?.entityId,
                        formData: action?.response,
                    }),
                );
            }
        });
        this.listenTo(
            ...dynamicFormsActions.formDataCreateAndUpdateFailureActions,
        ).subscribe((action: any) => {
            if (this.formId === action?.formId) {
                this.formEvent.emit(new FormFrameworkSaveFailedEvent());
            }
        });

        this.externalActionsHandler
            .getTriggerSave$()
            ?.pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.triggerSave = Date.now();
                this.cdr.markForCheck();
            });

        this.getFormTemplateId$
            .pipe(
                switchMap((condition) =>
                    iif(
                        () => Boolean(condition),
                        this.dynamicFormsService
                            .getFormTemplateInstance(
                                this.formTemplateId,
                                this.formTemplateEntityType,
                                this.formTemplateEntityId,
                            )
                            .pipe(
                                filter((res) => Boolean(res)),
                                tap((res) => (this.getFormTemplateData = res)),
                                take(1),
                            ),
                        of(null),
                    ),
                ),
                tap((res: FormTemplateInstance | null) => {
                    if (res) {
                        this.formId = res.formName as string;
                        this.formData = res;
                    }

                    this.formPresentationService.queryFormDefinition(
                        this.formId,
                    );
                }),
                switchMapTo(this.getAllFormInfo$),
            )
            .subscribe((res) => {
                this.formDefinition = res[0];
                this.formLayout = res[0]?.layout as FormLayout;
                this.formData = res[1];
                this.isLoadingData = false;
                this.formEvent.emit(new FormFrameworkLoadedEvent());
                this.cdr.detectChanges();
            });

        this.getFormTemplateId$.next(this.formTemplateId);

        this.formDataIsSaving$ = combineLatest(
            this.store$.select(dynamicFormsSelectors.getIsCreatingFormData, {
                id: this.formId,
            }),
            this.store$.select(dynamicFormsSelectors.getIsUpdatingFormData, {
                id: this.formId,
            }),
        ).pipe(
            takeUntil(this.destroyed$),
            map((values) => values.some((value) => value)),
        );

        this.formDataHasError$ = this.store$.select(
            dynamicFormsSelectors.getHasLoadingFormDataError,
            {
                formId: this.formId,
                entityId: this.entityId,
            },
        );
    }

    loadFormData(
        formDefinition: FormDefinition,
        queryParams?: KeyValueObject,
    ): void {
        if (this.getFormTemplateData || this.emitInsteadOfPost) {
            return;
        }

        if (this.appId) {
            this.formData$ = this.queryParams?.['appcontextid']
                ? this.dynamicFormsService.getAppFormDataWithContext(
                      this.appId,
                      this.formId,
                      this.entityId,
                  )
                : (this.formData$ = this.dynamicFormsService.getAppFormData(
                      this.appId,
                      queryParams,
                  ));
        } else {
            if (this.entityId) {
                this.formData$ = this.dynamicFormsService.getFormData(
                    this.formId,
                    this.entityId,
                    formDefinition.endpointPath,
                    {
                        ...this.queryParams,
                        ...queryParams,
                    },
                );
            }

            if (this.customEndpoint) {
                this.formData$ =
                    this.dynamicFormsService.getFormDataForCustomEndpoint(
                        this.customEndpoint,
                    );
            }

            if (
                !this.entityId &&
                !this.formTemplateId &&
                !this.customEndpoint
            ) {
                this.formData$ =
                    this.dynamicFormsService.getFormInitialisationData(
                        this.formId,
                        this.initialisationParams,
                    );
            }
        }
    }

    onFormTitleReceived(formTitle: string): void {
        this.formTitleReceived.emit(formTitle);
    }

    onCancelClick(): void {
        this.externalActionsHandler.closeSpawnedForms();
    }

    onSaveClick(formData: FormStateData): void {
        this.formEvent.emit(new FormFrameworkSaveStartEvent());
        this.saveForm(formData, true);
    }

    onSaveDraftClicked(formData: FormStateData): void {
        this.saveForm(formData, false);
    }

    onCompletionSummaryChanged(completionSummary: FormCompletionSummary): void {
        if (this.formId && this.entityId) {
            this.store$.dispatch(
                dynamicFormsActions.saveCompletionSummary({
                    formId: this.formId,
                    entityId: this.entityId,
                    completionSummary,
                }),
            );
        }
    }

    saveForm(formData: FormStateData, publish: boolean): void {
        this.formPresentationService.formDefinition$
            .pipe(
                filter((formDefinition) => Boolean(formDefinition)),
                take(1),
            )
            .subscribe((formDefinition: FormDefinition) => {
                if (this.emitInsteadOfPost) {
                    this.store$.dispatch(
                        dynamicFormsActions.emitFormData({
                            data: this.emitData,
                            value: formData.value,
                        }),
                    );

                    return;
                }

                if (formDefinition.standardEndpoint && !this.customEndpoint) {
                    this.store$.dispatch(
                        dynamicFormsActions.updateStandardFormData({
                            formId: this.formId,
                            entityId: this.entityId,
                            formData: formData.value,
                            publish,
                            saveMode: formDefinition.saveModes,
                            queryParams: formData.queryParams,
                        }),
                    );
                } else {
                    if (this.entityId && !this.customEndpoint) {
                        this.store$.dispatch(
                            dynamicFormsActions.updateNonStandardFormData({
                                formId: this.formId,
                                entityId: this.entityId,
                                formData: formData.value,
                                endpointPath:
                                    formDefinition.endpointPath as string,
                            }),
                        );
                    } else {
                        if (!this.customEndpoint) {
                            this.store$.dispatch(
                                dynamicFormsActions.createNonStandardFormData({
                                    formId: this.formId,
                                    formData: formData.value,
                                    endpointPath:
                                        formDefinition.endpointPath as string,
                                }),
                            );
                        }
                    }
                }

                if (this.customEndpoint) {
                    this.store$.dispatch(
                        dynamicFormsActions.putCustomEndpointFormData({
                            formId: this.formId,
                            formData: formData.value,
                            customEndpoint: this.customEndpoint,
                        }),
                    );
                }
            });
    }

    formEventHandler(event: FormFrameworkEvent): void {
        this.formEvent.emit(event);
    }

    onFormFunction(formFunctionAndData: {
        formFunction: string;
        formData: Record<string, any>;
    }): void {
        this.dynamicFormsService
            .getFormFunctionResult(this.formId, {
                functionName: formFunctionAndData.formFunction,
                formData: formFunctionAndData.formData,
            })
            .pipe(take(1))
            .subscribe();
    }

    private listenTo(...listenedActions: any[]): Observable<Action> {
        return this.actions$.pipe(
            takeUntil(this.destroyed$),
            filter((action: Action) =>
                listenedActions.some(
                    (listenedAction) => listenedAction.type === action.type,
                ),
            ),
        );
    }
}
