import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Config } from '@wdx/shared/utils';

@Injectable({
    providedIn: 'root',
})
export class SentryService {
    init(config: Config) {
        Sentry.registerSpanErrorInstrumentation();
        Sentry.init({
            dsn: config?.Sentry?.SentryAPI,
            integrations: [
                // Registers and configures the Tracing integration,
                // which automatically instruments your application to monitor its
                // performance, including custom Angular routing instrumentation
                Sentry.browserTracingIntegration(),
            ],
            tracePropagationTargets: [
                config.API_BASE,
                ...(config?.Sentry?.SentryTracingOrigin || []),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: parseFloat(config?.Sentry?.SentryTraceSampleRace),
        });
    }
}
