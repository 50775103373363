import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { PushPipe } from '@ngrx/component';
import { EntityRoutePipe } from '@wdx/clmi/utils/pipes';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxStopPropagationDirective } from '@wdx/shared/components/wdx-stop-propagation';
import { TranslatePipe, TranslateTokenisedStringPipe } from '@wdx/shared/utils';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeAvatarGroupModule } from '../../../components/molecules/molecule-avatar-group/molecule-avatar-group.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { MappersModule } from '../../../pipes/mappers/mappers.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { CommentsModule } from '../../../shared/features/comments';
import { ActivityStatusTagModule } from '../../../shared/features/to-do/components/activity-status-tag/activity-status-tag.module';
import { ToDoModule } from '../../../shared/features/to-do/to-do.module';
import { MoleculeActivityHeaderComponent } from './molecule-activity-header.component';

@NgModule({
    imports: [
        ActivityStatusTagModule,
        AtomIconButtonModule,
        CommentsModule,
        CommonModule,
        DirectivesModule,
        EntityRoutePipe,
        FormsModule,
        MappersModule,
        MoleculeAvatarGroupModule,
        NgbDatepickerModule,
        PipesModule,
        PushPipe,
        RouterModule,
        ToDoModule,
        WdxBadgeModule,
        WdxStopPropagationDirective,
        WdxIconModule,
        TranslateTokenisedStringPipe,
    ],
    declarations: [MoleculeActivityHeaderComponent],
    exports: [MoleculeActivityHeaderComponent],
})
export class MoleculeActivityHeaderModule {}
