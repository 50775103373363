import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PushPipe } from '@ngrx/component';
import { MentionModule } from 'angular-mentions';

import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { WdxCheckboxModule } from '@wdx/shared/components/wdx-checkbox';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxInputTextModule } from '@wdx/shared/components/wdx-input-text';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import {
    TranslateTokenisedStringPipe,
    WdxDateTimePipe,
    WdxMarkdownToHtmlPipe,
    WdxSafeHtmlPipe,
} from '@wdx/shared/utils';

import { ClmiFeaturesChatFeatureModule } from '@wdx/clmi/features/chat';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { WdxMultiSelectComponent } from '@wdx/shared/components/wdx-multi-select';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomAvatarModule } from '../../../components/atoms/atom-avatar/atom-avatar.module';
import { AtomFeatureSvgModule } from '../../../components/atoms/atom-feature-svg/atom-feature-svg.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeAvatarInfoModule } from '../../../components/molecules/molecule-avatar-info/molecule-avatar-info.module';
import { MoleculeAvatarModule } from '../../../components/molecules/molecule-avatar/molecule-avatar.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { CommentButtonComponent } from './comment-button/comment-button.component';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { CommentInputBoxComponent } from './comment-input-box/comment-input-box.component';
import { CommentInputComponent } from './comment-input/comment-input.component';
import { CommentComponent } from './comment/comment.component';
import { CommentsModalComponent } from './comments-modal/comments-modal.component';
import { CommentsComponent } from './comments.component';
import { CommentCardComponent } from './shared/components/comment-card/comment-card.component';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomFeatureSvgModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        MentionModule,
        MoleculeAvatarInfoModule,
        MoleculeAvatarModule,
        NgbPopoverModule,
        OrganismModalModule,
        PipesModule,
        PushPipe,
        WdxBadgeModule,
        WdxCheckboxModule,
        WdxIconButtonModule,
        WdxIconModule,
        WdxInputTextModule,
        WdxMarkdownToHtmlPipe,
        WdxSafeHtmlPipe,
        WdxSpinnerModule,
        WdxDateTimePipe,
        AtomFeatureSvgModule,
        RouterModule,
        WdxButtonModule,
        AtomAvatarModule,
        PartyToAvatarPipe,
        ClmiFeaturesChatFeatureModule,
        WdxIsLoadingModule,
        WdxMultiSelectComponent,
        ReactiveFormsModule,
        TranslateTokenisedStringPipe,
    ],
    declarations: [
        CommentButtonComponent,
        CommentComponent,
        CommentInputComponent,
        CommentsComponent,
        CommentsModalComponent,
        CommentCardComponent,
        CommentDialogComponent,
        CommentInputBoxComponent,
    ],
    exports: [
        CommentButtonComponent,
        CommentsComponent,
        CommentsModalComponent,
    ],
})
export class CommentsModule {}
