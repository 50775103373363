<div
    ngbDropdown
    #ctxDropdown="ngbDropdown"
    display="dynamic"
    [container]="parentContainer"
    [autoClose]="autoClose ?? (menuHasLinkedMenu ? 'outside' : true)"
    [placement]="placement"
>
    <atom-dropdown-button
        *ngIf="showButton"
        ngbDropdownAnchor
        [id]="ctxId"
        [btnClass]="btnClass"
        [altText]="altText"
        [disabled]="disabled"
        [hideDropdownChevron]="hideDropdownChevron"
        [cySelector]="cySelector"
        (click)="onClick($event)"
    >
        <ng-container [ngSwitch]="buttonStyle">
            <ng-container *ngSwitchCase="DROPDOWN_BUTTON_STYLE.Result">
                <ng-container *ngIf="selectedMenuItems?.length">
                    <span
                        *ngFor="let item of selectedMenuItems; let i = index"
                        class="me-2"
                        [attr.data-cy]="item.cySelector"
                    >
                        {{
                            item.translationKey
                                ? (item.translationKey | translate)
                                : item.label
                        }}{{
                            i < selectedMenuItems.length - 1 ? ',&nbsp;' : ''
                        }}
                    </span>
                </ng-container>
                <ng-container *ngIf="!selectedMenuItems?.length"
                    ><span class="me-2">{{
                        SELECT | translate
                    }}</span></ng-container
                >
            </ng-container>
            <ng-container *ngSwitchCase="DROPDOWN_BUTTON_STYLE.Label">
                <ng-content></ng-content>
            </ng-container>
        </ng-container>
    </atom-dropdown-button>

    <div
        ngbDropdownMenu
        *ngIf="!disabled"
        [class]="size ? 'dropdown-menu-' + size : ''"
        [class.dropdown-menu-search]="hasSearch"
        [attr.aria-labelledby]="ctxId"
    >
        <div *ngIf="menuTitle" class="dropdown-header flex-shrink-0">
            <h5>
                {{ menuTitle }}
            </h5>
        </div>
        <div *ngIf="hasSearch" class="mx-1">
            <molecule-input-search></molecule-input-search>
        </div>
        <atom-async-data [isLoading]="isLoading">
            <molecule-context-menu
                [class.dropdown-menu-search-content]="hasSearch"
                [menu]="menu"
                [isMulti]="isMulti"
                [highlightSelected]="highlightSelected"
                [initialValue]="selectedMenuItemValues"
                [cySelector]="cySelector"
                [attr.data-cy]="cySelector + '-menu'"
                (singleItemSelected)="onSingleItemSelected($event)"
                (multipleItemsSelected)="onMultipleItemsSelected($event)"
            ></molecule-context-menu>
        </atom-async-data>
    </div>
</div>
