import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SignalrEvent } from '@wdx/shared/utils';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EngagementState } from '../../models/engagement-state.model';
import { EngagementTrigger } from '../../models/engagement-trigger.model';
import * as engagementBarActions from './../engagement-bar/engagement-bar.actions';
import * as engagementsActions from './engagements.actions';

@Injectable()
export class EngagementsEffects {
    private actions$ = inject(Actions);

    listenToEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    // TODO(sentry): Could not automatically migrate - see https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
                    hub.on(SignalrEvent.VisitorEngaged).pipe(
                        switchMap((visitor: any) =>
                            of(
                                engagementBarActions.setParty({
                                    party: visitor.matches[0],
                                }),
                                engagementBarActions.setActivityId({
                                    activityId: visitor.activityId,
                                }),
                                engagementBarActions.setEngagementState({
                                    engagementState: {
                                        [EngagementTrigger.Start]:
                                            EngagementState.Started,
                                        [EngagementTrigger.End]:
                                            EngagementState.Ended,
                                    }[visitor.trigger],
                                }),
                                engagementBarActions.setVisibility({
                                    isVisible: true,
                                }),
                                engagementsActions.visitorEngagedSuccess(
                                    visitor
                                )
                            )
                        )
                    )
                )
            )
        )
    );
}
