import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Document,
    DocumentRequirement,
    Regarding,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ConfigService } from '../../services/config.service';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';

@Injectable()
export class DocumentsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getDocumentsForClientProduct(
        paginationOptions: PaginationOptions,
        clientProductId: string
    ): Observable<PaginatedApiResponse<Document>> {
        return this.http.get<PaginatedApiResponse<Document>>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${clientProductId}/document`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    getDocumentsForEntityId(
        entityType: SystemEntity,
        entityId: string
    ): Observable<Document[]> {
        return this.http.get<Document[]>(
            `${this.config.getConfiguration().API_BASE}/${String(
                entityType
            ).toLowerCase()}/${entityId}/documents`
        );
    }

    deleteDocument(documentId: string): Observable<any> {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/document/${documentId}`,
            {}
        );
    }

    dissociateDocument(id: string, regarding: Regarding[]): Observable<any> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/document/${id}/dissociate`,
            regarding || [],
            {}
        );
    }

    getDocumentRequirement(id: string): Observable<DocumentRequirement> {
        return this.http.get<DocumentRequirement>(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirement/${id}`
        );
    }

    uploadFile(
        id: string,
        entityId: string,
        entityType: SystemEntity,
        file: File
    ): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append(file.name, file);

        return this.http.request(
            new HttpRequest(
                'POST',
                `${
                    this.config.getConfiguration().API_BASE
                }/${entityType.toLowerCase()}/${entityId}/document`,
                formData
            )
        );
    }
}
