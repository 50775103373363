<atom-avatar
    class="d-none d-lg-block"
    container="body"
    display="dynamic"
    popoverClass="popover-no-padding popover-avatar"
    [class.pointer]="isAvatarInfoEnabled"
    [avatar]="avatar"
    [size]="size"
    [highlightBorder]="highlightBorder"
    [ngbPopover]="isAvatarInfoEnabled ? avatarInfoPopover : null"
    (click)="$event.stopPropagation()"
></atom-avatar>
<atom-avatar
    class="d-block d-lg-none"
    wdxStopPropagation
    [avatar]="avatar"
    [size]="size"
    [highlightBorder]="highlightBorder"
    (click)="openAvatarModal()"
></atom-avatar>

<ng-template #avatarInfoPopover>
    <molecule-avatar-info
        *ngIf="avatar"
        [avatar]="avatar"
        [editModalId]="editModalId"
    ></molecule-avatar-info>
</ng-template>
