<molecule-widget-container
    [widgetTitle]="widgetTitle"
    [widgetSize]="widgetSize"
    [spokeButton]="spokeButton"
    [isLoading]="isLoading"
    [hasError]="hasError"
    [count]="totalRecordCount"
    [hideCount]="hideCount"
    [numberToCompareToLimit]="articleListItems?.length"
    [limit]="limit"
    [showNoDataMessage]="!articleListItems?.length"
    (spokeButtonClicked)="onSpokeButtonClicked()"
>
    <div
        *ngIf="showArticlesListActionsButtons"
        class="widget-header-action-buttons"
    >
        <ng-content select.articles-list-action-buttons></ng-content>
    </div>
    <div class="data-list-content overflow-hidden">
        <div class="data-list-content__article-row grid">
            <ng-container
                *ngFor="let article of articleListItems | slice : 0 : limit"
            >
                <molecule-article-card
                    class="data-list-content__card-container"
                    [responsiveColumns]="{ staticColumns: articlesPerRow }"
                    [showArticleImage]="false"
                    [article]="article"
                    [showLinkButton]="true"
                    [isLinked]="true"
                    [linkToEntityType]="linkToEntityType"
                    [linkToEntityId]="linkToEntityId"
                ></molecule-article-card>
            </ng-container>
        </div>
    </div>
</molecule-widget-container>

<atom-modal-wrapper
    size="xl"
    [modalId]="modalId"
    [modalTemplate]="articlesModal"
>
    <ng-template #articlesModal>
        <organism-modal modalTitle="All Articles">
            <div
                class="data-list-content h-100 overflow-x-hidden overflow-y-auto p-4"
            >
                <div
                    class="data-list-content__article-row row align-items-stretch"
                >
                    <ng-container
                        *ngFor="let articleListItem of articleListItems"
                    >
                        <molecule-article-card
                            class="data-list-content__card-container p-1"
                            [responsiveColumns]="{
                                staticColumns: articlesPerRow
                            }"
                            [showArticleImage]="showArticleImage"
                            [article]="articleListItem"
                            [showLinkButton]="true"
                            [isLinked]="true"
                            [linkToEntityType]="linkToEntityType"
                            [linkToEntityId]="linkToEntityId"
                        ></molecule-article-card>
                    </ng-container>
                </div>
            </div>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
