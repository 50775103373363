<div
    class="btn-group split-button-group"
    ngbDropdown
    role="group"
    aria-label="Button group with nested dropdown"
    [placement]="menuPlacement"
>
    <ng-content></ng-content>
    <button
        ngbDropdownToggle
        class="btn dropdown-toggle-split"
        [ngClass]="themeClass"
        [attr.disabled]="isLoading || null"
        data-cy="split-button-toggle-button"
    >
        <span class="visually-hidden">Dropdown Toggle</span>
        <wdx-icon size="fit" [icon]="ICON_DROPDOWN"></wdx-icon>
    </button>
    <div class="dropdown-menu" ngbDropdownMenu>
        <ng-container *ngFor="let item of menuItems">
            <button
                ngbDropdownItem
                [disabled]="disabled"
                (click)="onMenuItemSelected(item)"
            >
                {{ item.label }}
            </button>
        </ng-container>
    </div>
</div>
