import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as rootReducer from '../../state/_setup/reducers';
import * as clientsActions from './clients.actions';
import { ClientsService } from './clients.service';

@Injectable()
export class ClientsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private clientsService = inject(ClientsService);

    getClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getClient),
            switchMap((action) =>
                this.clientsService.getClient(action.clientId).pipe(
                    map((client) =>
                        clientsActions.getClientSuccess({ client })
                    ),
                    catchError((error) =>
                        of(
                            clientsActions.getClientFailure({
                                clientId: action.clientId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getFavourites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getFavourites),
            switchMap(() =>
                this.clientsService.getFavourites().pipe(
                    map((clients) =>
                        clientsActions.getFavouritesSuccess({
                            favourites: clients,
                        })
                    ),
                    catchError((error) =>
                        of(clientsActions.getFavouritesFailure({ error }))
                    )
                )
            )
        )
    );

    getAccountHoldersForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getAccountHoldersForClient),
            switchMap((action) =>
                this.clientsService
                    .getAccountHoldersForClient(action.clientId)
                    .pipe(
                        map((accountHoldersForClient) =>
                            clientsActions.getAccountHoldersForClientSuccess({
                                accountHoldersForClient,
                                clientId: action.clientId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                clientsActions.getAccountHoldersForClientFailure(
                                    {
                                        clientId: action.clientId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getOtherPartiesForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getOtherPartiesForClient),
            switchMap((action) =>
                this.clientsService
                    .getOtherPartiesForClient(action.clientId)
                    .pipe(
                        map((otherPartiesForClient) =>
                            clientsActions.getOtherPartiesForClientSuccess({
                                otherPartiesForClient,
                                clientId: action.clientId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                clientsActions.getOtherPartiesForClientFailure({
                                    clientId: action.clientId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getApplicableRolesForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getApplicableRolesForClient),
            switchMap((action) =>
                this.clientsService
                    .getApplicableRolesForClient(action.clientId)
                    .pipe(
                        map((applicableRoles) =>
                            clientsActions.getApplicableRolesForClientSuccess({
                                applicableRoles,
                                clientId: action.clientId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                clientsActions.getApplicableRolesForClientFailure(
                                    {
                                        clientId: action.clientId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getPartiesForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getPartiesForClient),
            switchMap((action) =>
                this.clientsService.getPartiesForClient(action.clientId).pipe(
                    map((clientParties) =>
                        clientsActions.getPartiesForClientSuccess({
                            clientParties,
                            clientId: action.clientId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientsActions.getPartiesForClientFailure({
                                clientId: action.clientId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getKeyStatsForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getKeyStatsForClient),
            switchMap((action) =>
                this.clientsService.getKeyStatsForClient(action.clientId).pipe(
                    map((keyStats) =>
                        clientsActions.getKeyStatsForClientSuccess({
                            keyStatsForClient: keyStats,
                            clientId: action.clientId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientsActions.getKeyStatsForClientFailure({
                                clientId: action.clientId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getHeatMapForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getHeatMapForClient),
            switchMap((action) =>
                this.clientsService.getHeatMapForClient(action.clientId).pipe(
                    map((heatMap) =>
                        clientsActions.getHeatMapForClientSuccess({
                            clientId: action.clientId,
                            heatMap,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientsActions.getHeatMapForClientFailure({
                                clientId: action.clientId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteRelationshipForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.deleteRelationshipForId),
            mergeMap((action) =>
                this.clientsService
                    .deleteRelationshipForId(action.relationshipId)
                    .pipe(
                        map(() =>
                            clientsActions.deleteRelationshipForIdSuccess({
                                clientId: action.clientId,
                                relationshipId: action.relationshipId,
                                role: action.role,
                            })
                        ),
                        catchError((error) =>
                            of(
                                clientsActions.deleteRelationshipForIdFailure({
                                    clientId: action.clientId,
                                    relationshipId: action.relationshipId,
                                    role: action.role,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getClientProductType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientsActions.getClientProductType),
            switchMap((action) =>
                this.clientsService.getClientProductType(action.clientId).pipe(
                    map((productTypes) =>
                        clientsActions.getClientProductTypeSuccess({
                            productTypes,
                            clientId: action.clientId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientsActions.getClientProductTypeFailure({
                                clientId: action.clientId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
