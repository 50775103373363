import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterFeatureState } from './custom-route-serializer';

const selectRouterSlice = createFeatureSelector<RouterFeatureState>('router');

export const selectRouterState = createSelector(
    selectRouterSlice,
    (router) => router && router.state,
);

export const selectQueryParams = createSelector(
    selectRouterState,
    (route) => route && route.queryParams,
);

export const selectQueryParam = (param: string) =>
    createSelector(selectQueryParams, (params) => params && params[param]);

export const selectRouteData = createSelector(
    selectRouterState,
    (route) => route && route.data,
);

export const selectRouteDataParam = (param: string) =>
    createSelector(selectRouteData, (data) => data && data[param]);

export const selectRouteParams = createSelector(
    selectRouterState,
    (route) => route && route.params,
);

export const selectRouteParam = (param: string) =>
    createSelector(selectRouteParams, (params) => params && params[param]);

export const selectUrl = createSelector(
    selectRouterState,
    (route) => route && route.url,
);

export const selectPath = createSelector(
    selectRouterState,
    (route) => route && route.path,
);
