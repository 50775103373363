<wdx-radio-button
    *ngFor="let config of buttonsConfig; index as i; last as last"
    [label]="config.label"
    [icon]="config.icon"
    [disabled]="disabled"
    [value]="config.value"
    [checked]="config.value === checkedValue"
    [size]="size"
    (click)="selected(config.value)"
    [class.me-1]="!last"
></wdx-radio-button>
