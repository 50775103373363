import { Action, createReducer, on } from '@ngrx/store';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as importEntitiesActionsActions from './import-entities.actions';
import { ImportCategory } from '@wdx/clmi/api-models';

export const ImportEntitiesStore = 'importEntities';

export interface State {
    imports?: CrudState<ImportCategory>;
}

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        importEntitiesActionsActions.getImportEntities,
        (state): State => ({
            ...state,
            imports: {
                ...(state.imports || ({} as CrudState<ImportCategory>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        }),
    ),

    on(
        importEntitiesActionsActions.getImportEntitiesSuccess,
        (state, props): State => ({
            ...state,
            imports: {
                ...(state.imports || ({} as CrudState<ImportCategory>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.imports,
            },
        }),
    ),
    on(
        importEntitiesActionsActions.getImportEntitiesFailure,
        (state): State => ({
            ...state,
            imports: {
                ...(state.imports || ({} as CrudState<ImportCategory>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
