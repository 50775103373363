import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrganismModalComponent } from './organism-modal.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxMarkdownToHtmlPipe, WdxSafeHtmlPipe } from '@wdx/shared/utils';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';

@NgModule({
    imports: [CommonModule, WdxMarkdownToHtmlPipe, WdxSafeHtmlPipe, WdxIconModule, NgbPopoverModule],
    declarations: [OrganismModalComponent],
    exports: [OrganismModalComponent],
})
export class OrganismModalModule {}
