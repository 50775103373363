import { Injectable } from '@angular/core';
import { filter, map, Observable, tap } from 'rxjs';

import { Action, ActionsSubject, select, Store } from '@ngrx/store';

import { SystemEntity } from '@wdx/clmi/api-models';
import * as viewActions from '../../state/views/views.actions';
import { getViewStateForEntity } from '../../state/views/views.selectors';
import { VIEWS_FEATURE_KEY, State } from '../../state/views/views.reducer';
import { ofType } from '@ngrx/effects';

@Injectable()
export class ViewsFacadeService {
    constructor(
        private actionsSubject$: ActionsSubject,
        private store$: Store<{ [VIEWS_FEATURE_KEY]: State }>,
    ) {}

    /**
     * The method should be used to delete a personal view.
     *
     * @param systemEntityType: SystemEntity
     * @param viewId: string
     * @returns Action
     */
    deleteViewAction(systemEntityType: SystemEntity, viewId: string): Action {
        return viewActions.deleteByEntityType({
            entityType: systemEntityType,
            viewId,
        });
    }

    /**
     * An ActionsSubject that emits action payload on `deleteByEntityTypeSuccess`
     * @returns Observable<View>
     */
    deleteViewSuccess$(): Observable<{
        entityType: SystemEntity;
        viewId: string;
    }> {
        return this.actionsSubject$.pipe(
            ofType(viewActions.deleteByEntityTypeSuccess),
        );
    }

    getViewsForEntityType$(
        entityType: SystemEntity,
        typeCode?: string,
        isContextual?: boolean,
    ) {
        return this.store$.pipe(
            select(
                getViewStateForEntity({ entityType, typeCode, isContextual }),
            ),
            tap((viewState) => {
                if (
                    !viewState ||
                    (!viewState.isLoadingList &&
                        !viewState.list &&
                        !viewState.hasLoadingListError)
                ) {
                    this.dispatchGetViewsForEntityType(
                        entityType,
                        typeCode,
                        isContextual,
                    );
                }
            }),
            filter((viewState) => Boolean(viewState?.list)),
            map(({ list }) => list),
        );
    }

    dispatchGetViewsForEntityType(
        entityType: SystemEntity,
        typeCode?: string,
        isContextual?: boolean,
    ) {
        this.store$.dispatch(
            viewActions.getForEntityType({
                entityType,
                typeCode,
                isContextual,
            }),
        );
    }
}
