/**
 * Manual Feature types (not part of generated api-models as object is returned as an untyped key/value object)
 */

export type Features = Record<string, boolean>;
export type FeatureFlagValue = string;
export type FeatureFlagKey = string;
export type FeatureFlagEnum = Record<FeatureFlagKey, FeatureFlagValue>;
export enum FeatureFlag {
    AddressLookupEnabled = 'addressLookupEnabled',
    Advisers = 'advisers',
    AllowMessageFileUpload = 'allowMessageFileUpload',
    ArticleDistribution = 'articleDistribution',
    Articles = 'articles',
    Calendar = 'calendar',
    CaseCharts = 'caseCharts',
    Cases = 'cases',
    ChangeTenant = 'changeTenant',
    ClientCharts = 'clientCharts',
    ClientPortfolioAssetClasses = 'clientPortfolioAssetClasses',
    ClientPortfolioPositions = 'clientPortfolioPositions',
    ClientPortfolioPerformance = 'clientPortfolioPerformance',
    ClientPortfolioTopMovers = 'clientPortfolioTopMovers',
    ClientPortfolioTransactions = 'clientPortfolioTransactions',
    ClientPortfolioStandingOrders = 'clientPortfolioStandingOrders',
    ClientPortfolioAccounts = 'clientPortfolioAccounts',
    ClientProductCharts = 'clientProductCharts',
    ClientProductTeamMembersWidgetReadOnly = 'clientProductTeamMembersWidgetReadOnly',
    Clients = 'clients',
    CustomViews = 'customViews',
    Dashboard = 'dashboard',
    DMSCLMiUpload = 'dMSCLMiUpload',
    DocumentManagement = 'documentManagement',
    DMSExternalUpload = 'dMS-CLMi-upload',
    DocumentGenerationAdhoc = 'documentGenerationAdhoc',
    DontEncodeIds = 'dontEncodeIds',
    Duplicates = 'duplicates',
    EnableAttachments = 'enableAttachments',
    ESignatureSupport = 'eSignatureSupport',
    Events = 'events',
    Exports = 'exports',
    ExternalUserApp = 'externalUserApp',
    ExternalTasks = 'externalTasks',
    ExtractPDF = 'extractPDF',
    Feed = 'feed',
    GlobalSearch = 'globalSearch',
    ImportAdviser = 'importAdviser',
    ImportIntermediary = 'importIntermediary',
    ImportLead = 'importLead',
    ImportOpportunity = 'importOpportunity',
    ImportProductProvider = 'importProductProvider',
    Imports = 'imports',
    Inbox = 'inbox',
    InboxCharts = 'inboxCharts',
    Intermediaries = 'intermediaries',
    InvestmentDataServices = 'investmentDataServices',
    LeadCharts = 'leadCharts',
    Leads = 'leads',
    Lists = 'lists',
    Marketing = 'marketing',
    NetworkChart = 'networkChart',
    Organisation = 'organisation',
    OrganisationCharts = 'organisationCharts',
    OrganisationConnections = 'organisationConnections',
    PeopleCharts = 'peopleCharts',
    Pipeline = 'pipeline',
    PipelineCharts = 'pipelineCharts',
    PortfolioAssetClasses = 'portfolioAssetClasses',
    PortfolioPerformance = 'portfolioPerformance',
    PortfolioPositions = 'portfolioPositions',
    PortfolioStandingOrders = 'portfolioStandingOrders',
    PortfolioTopMovers = 'portfolioTopMovers',
    PortfolioTransactions = 'portfolioTransactions',
    PortfolioAccounts = 'portfolioAccounts',
    PowerBIPortfolio = 'powerBIPortfolio',
    ProductProviders = 'productProviders',
    Products = 'products',
    Queues = 'queues',
    Qwil = 'qwil',
    SecureMessage = 'secureMessage',
    SendExternalUserInvite = 'sendExternalUserInvite',
    TestForms = 'testForms',
    ToastErrors = 'toastErrors',
    Unblu = 'unblu',
    ReferenceDataImportExport = 'referenceDataImportExport',
    MultipleLanguages = 'multipleLanguages',
}
