import { createAction, props } from '@ngrx/store';
import {
    DocumentTemplate,
    StatusType,
    SystemEntity,
} from '@wdx/clmi/api-models';

export const getDocumentTemplates = createAction(
    '[Document Template] Get Templates',
    props<{
        entityType?: SystemEntity;
        documentTypeCode?: string;
    }>()
);

export const getDocumentTemplatesSuccess = createAction(
    '[Document Template] Get Templates Success',
    props<{
        documentTemplates: DocumentTemplate[];
        entityType?: SystemEntity;
        documentTypeCode?: string;
    }>()
);

export const getDocumentTemplatesFailure = createAction(
    '[Document Template] Get Templates Failure',
    props<{
        entityType?: SystemEntity;
        documentTypeCode?: string;
        error: Error;
    }>()
);

export const getDocument = createAction(
    '[Document Template] Get Document',
    props<{
        id?: string;
        entityId: string;
    }>()
);

export const getDocumentSuccess = createAction(
    '[Document Template] Get Document Success'
);

export const getDocumentFailure = createAction(
    '[Document Template] Get Document Failure',
    props<{ error: Error }>()
);

export const toggleStatus = createAction(
    '[Document Template] Toggle Status',
    props<{
        id: string;
        newValue?: boolean;
    }>()
);

export const activateDocumentTemplate = createAction(
    '[Document Template] Activate Templates',
    props<{
        id: string;
        status?: StatusType;
    }>()
);

export const activateDocumentTemplateSuccess = createAction(
    '[Document Template] Activate Templates Success',
    props<{
        id: string;
        status?: StatusType;
    }>()
);

export const activateDocumentTemplateFailure = createAction(
    '[Document Template] Activate Templates Failure',
    props<{
        error: Error;
    }>()
);

export const inactivateDocumentTemplate = createAction(
    '[Document Template] Inactivate Templates',
    props<{
        id: string;
        status?: StatusType.Inactive;
    }>()
);

export const inactivateDocumentTemplateSuccess = createAction(
    '[Document Template] Inactivate Templates Success',
    props<{
        id: string;
        status?: StatusType.Inactive;
    }>()
);

export const inactivateDocumentTemplateFailure = createAction(
    '[Document Template] Inactivate Templates Failure',
    props<{
        error: Error;
    }>()
);

export const deleteDocumentTemplate = createAction(
    '[Document Template] Delete Templates',
    props<{
        id: string;
    }>()
);

export const deleteTemplateSuccess = createAction(
    '[Document Template] Delete Templates Success',
    props<{
        id: string;
    }>()
);

export const deleteTemplateFailure = createAction(
    '[Document Template] Delete Templates Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const generateDocumentTemplate = createAction(
    '[Document Template] Generate Document Template',
    props<{
        entityId: string;
        entityType?: SystemEntity;
        templateId: string;
        documentTemplateType: SystemEntity;
    }>()
);

export const generateDocumentTemplateSuccess = createAction(
    '[Document Template] Generate Document Template Success',
    props<{
        entityId: string;
        entityType?: SystemEntity;
        templateId: string;
        documentTemplateType: SystemEntity;
    }>()
);

export const generateDocumentTemplateError = createAction(
    '[Document Template] Generate Document Template Error',
    props<{
        entityId: string;
        entityType?: SystemEntity;
        templateId: string;
        documentTemplateType: SystemEntity;
        error: Error;
    }>()
);

export const resetResults = createAction(
    '[Document Template] Reset Results',
    props<{
        entityType: SystemEntity;
    }>()
);
