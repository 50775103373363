import { Component, Input } from '@angular/core';
import { ICON_LOCK } from '../../../constants/icons.constants';
import { GridRows } from '../../../models/grid.model';
import { IdList } from '../../../models/id-list.model';
import { ListItem } from '../../../models/list-item.model';
import { PluralStringPipe } from '../../../pipes/plural-string.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-identifier-list',
    templateUrl: './molecule-identifier-list.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-flex align-items-center' },
})
export class MoleculeIdentifierListComponent {
    @Input() idList: IdList;
    @Input() truncate: boolean;
    @Input() limit = 2;
    @Input() rows = false;

    readonly ICON_LOCK = ICON_LOCK.icon;

    idListToHorizontalListItems(idList: IdList, limit: number): ListItem[] {
        if (!idList?.length) {
            return [];
        }
        const limitIndex = limit > 0 ? limit : 0;
        const limitIndexAllowLabel = limit - 1 > 0 ? limit - 1 : 0;
        const restrictedList =
            idList.length > limit
                ? idList.slice(0, limitIndexAllowLabel)
                : idList.length === limit
                ? idList.slice(0, limitIndex)
                : idList;
        return restrictedList.map((listItem) => ({
            label: listItem.id,
            subLabel: listItem.name ? `(${listItem.name})` : null,
            icon: listItem.isLocked ? ICON_LOCK.icon : null,
        }));
    }

    idListToGridRows(idList: IdList, limit: number): GridRows {
        if (!idList.length) {
            return [];
        }
        const restrictedList = idList.slice(limit - 1);
        return restrictedList.map((listItem) => [
            { icon: listItem.isLocked ? ICON_LOCK.icon : null },
            { label: `${listItem.name ? listItem.name + ' ' : ''}ID:` },
            { label: listItem.id },
        ]);
    }

    idListToPopoverLabel(idList: IdList, limit: number): ListItem[] {
        const gridRowCount = idList.length - (limit - 1);
        return [
            {
                label: `${gridRowCount} Other ${new PluralStringPipe().transform(
                    'Identifier',
                    gridRowCount,
                    'Identifiers'
                )}`,
                icon: idList.some((item) => item.isLocked)
                    ? ICON_LOCK.icon
                    : null,
            },
        ];
    }
}
