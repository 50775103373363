import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalManagementService } from '../../../services/modal-management.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-modal',
    templateUrl: './organism-modal.component.html',
})
export class OrganismModalComponent {
    @Input() modalTitle: string;
    @Input() headerClass: string;
    @Input() bodyClass: string;
    @Input() hideClose: boolean;
    @Input() guidanceText: string;

    @Output() modalClosed = new EventEmitter();

    constructor(private modalManagementService: ModalManagementService) {}

    onClose() {
        const modalId = this.modalManagementService.closeActiveModal();
        this.modalManagementService.activeModalClosed$.next(modalId);
        this.modalClosed.emit();
    }
}
