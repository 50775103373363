<atom-modal-wrapper [modalId]="modalId" [modalTemplate]="visibilityModal">
    <ng-template #visibilityModal>
        <organism-modal modalTitle="Visibility">
            <div class="d-flex flex-column h-100 overflow-hidden">
                <div
                    class="bg-muted modal-padding modal-padding--banner flex-grow-0 flex-shrink-0"
                >
                    <clmi-client-side-search
                        class="d-block"
                        [formInputData]="{ id: 'accessVisibilitySearch' }"
                        [searchArray]="accessVisibility"
                        (valueChanged)="onSearch($event)"
                    ></clmi-client-side-search>
                </div>
                <organism-table
                    class="d-block flex-grow-1 overflow-hidden"
                    [table]="searchResultsTable || accessVisibilityTable"
                    [isLoading]="isLoading"
                    [hasError]="hasError"
                ></organism-table>
            </div>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>

<ng-template #nameCell let-data="data">
    <molecule-info-card
        [avatars]="data.avatars"
        [primaryInfo]="data.name"
        [routerLinkData]="data.routerLink"
        [queryParamsData]="data.queryParams"
        [avatarIconDivider]="true"
        (linkClicked)="close()"
    ></molecule-info-card>
</ng-template>

<ng-template #reasonCell let-data="data">
    <ul class="list-unstyled">
        <ng-container
            *ngFor="let reason of data.reasons | keyvalue; let first = first"
        >
            <h6 [class.mt-1]="!first">
                {{ reason.key }}
            </h6>
            <ul class="list-unstyled">
                <li *ngFor="let value of reason.value">
                    <clmi-interpolation-display
                        [text]="value"
                        (linkSelected)="close()"
                    ></clmi-interpolation-display>
                </li>
            </ul>
        </ng-container>
    </ul>
</ng-template>
