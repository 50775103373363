import { createSelector } from '@ngrx/store';
import * as listsReducer from './lists.reducer';
import { ListType } from '@wdx/clmi/api-models';

/**
 * Reducer state selector
 */
export const getState = (state: {
    lists: listsReducer.State;
}): listsReducer.State => state.lists;

/**
 * Reducer property selectors
 */

export const getIsLoadingLists = createSelector(
    getState,
    (state: listsReducer.State) => state.lists.isLoadingList
);
export const getHasLoadingListsError = createSelector(
    getState,
    (state: listsReducer.State) => state.lists.hasLoadingListError
);
export const getLists = createSelector(
    getState,
    (state: listsReducer.State) => state.lists.list
);

export const getIsLoadingListForId = createSelector(
    getState,
    (state: listsReducer.State) => state.lists.isLoadingList
);
export const getHasLoadingListForIdError = createSelector(
    getState,
    (state: listsReducer.State) => state.lists.hasLoadingListError
);
export const getListForId = (listId) =>
    createSelector(getState, (state: listsReducer.State) =>
        state.lists?.list?.find((list) => list.id === listId)
    );

export const getIsLoadingMembersForId = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) => state.members[listId]?.isLoadingList
    );
export const getHasLoadingMembersForIdError = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) =>
            state.members[listId]?.hasLoadingListError
    );
export const getMembersForId = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) => state.members[listId]?.list
    );

export const getIsLoadingPartiesForId = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) => state.parties[listId]?.isLoadingList
    );
export const getHasLoadingPartiesForIdError = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) =>
            state.parties[listId]?.hasLoadingListError
    );
export const getPartiesForId = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) => state.parties[listId]?.list
    );

export const getIsLoadingPartyRolesForId = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) => state.partyRoles[listId]?.isLoadingList
    );
export const getHasLoadingPartyRolesForIdError = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) =>
            state.partyRoles[listId]?.hasLoadingListError
    );
export const getPartyRolesForId = (listId) =>
    createSelector(
        getState,
        (state: listsReducer.State) => state.partyRoles[listId]?.list
    );

export const getListsByType = (listType: ListType) =>
    createSelector(getState, (state) =>
        state.lists?.list?.filter((l) => l.type === listType)
    );
