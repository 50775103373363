import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { userSelectors } from '@wdx/clmi/api-services/services';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import * as rootReducer from '../state/_setup/reducers';

@Injectable()
export class AcceptedLanguageInterceptor implements HttpInterceptor {
    constructor(private store$: Store<rootReducer.State>) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return this.store$.select(userSelectors.getMeSelector).pipe(
            take(1),
            mergeMap((me) => {
                const LANG =
                    me && me.localeName
                        ? me.localeName
                        : navigator.languages[0];
                const tokenReq = request.clone({
                    setHeaders: {
                        'Accept-Language': LANG,
                    },
                });

                return next.handle(tokenReq);
            })
        );
    }
}
