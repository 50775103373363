import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { AtomFeatureSvgModule } from '../../../../../components/atoms/atom-feature-svg/atom-feature-svg.module';
import { AtomModalWrapperModule } from '../../../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeActionButtonModule } from '../../../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeDataListWrapperModule } from '../../../../../components/molecules/molecule-data-list-wrapper/molecule-data-list-wrapper.module';
import { OrganismModalModule } from '../../../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../../../directives/directives.module';
import { PipesModule } from '../../../../../pipes/pipes.module';
import { MoleculeArticleCardModule } from '../molecule-article-card/molecule-article-card.module';
import { OrganismArticlesListComponent } from './organism-articles-list.component';

@NgModule({
    imports: [
        AtomFeatureSvgModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeArticleCardModule,
        MoleculeDataListWrapperModule,
        OrganismModalModule,
        PipesModule,
        PushPipe,
    ],
    declarations: [OrganismArticlesListComponent],
    exports: [OrganismArticlesListComponent],
})
export class OrganismArticlesListModule {}
