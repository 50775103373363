import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as listsActions from './lists.actions';
import { ListsService } from './lists.service';

@Injectable()
export class ListsEffects {
    private actions$ = inject(Actions);
    private listsService = inject(ListsService);

    addList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.addList),
            switchMap((action) =>
                this.listsService.addList(action.list).pipe(
                    map((list) => listsActions.addListSuccess({ list })),
                    catchError((error) =>
                        of(listsActions.addListFailure({ error }))
                    )
                )
            )
        )
    );

    addPartiesToList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.addPartiesToList),
            switchMap((action) =>
                this.listsService
                    .addPartiesToList(
                        action.list,
                        action.parties,
                        action.lookupSourceType
                    )
                    .pipe(
                        map(() =>
                            listsActions.addPartiesToListSuccess({
                                list: action.list,
                                parties: action.parties,
                            })
                        ),
                        catchError((error) =>
                            of(
                                listsActions.addPartiesToListFailure({
                                    list: action.list,
                                    parties: action.parties,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addLookupResultsToList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.addLookupResultsToList),
            switchMap((action) =>
                this.listsService
                    .addLookupResultsToList(action.list, action.lookupResults)
                    .pipe(
                        map(() =>
                            listsActions.addLookupResultsToListSuccess({
                                list: action.list,
                                lookupResults: action.lookupResults,
                            })
                        ),
                        catchError((error) =>
                            of(
                                listsActions.addLookupResultsToListFailure({
                                    list: action.list,
                                    lookupResults: action.lookupResults,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteListForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.deleteListForId),
            switchMap((action) =>
                this.listsService.deleteListForId(action.listId).pipe(
                    map(() =>
                        listsActions.deleteListForIdSuccess({
                            listId: action.listId,
                        })
                    ),
                    catchError((error) =>
                        of(listsActions.deleteListForIdFailure({ error }))
                    )
                )
            )
        )
    );

    getLists$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.getLists),
            switchMap(() =>
                this.listsService.getLists().pipe(
                    map((lists) => listsActions.getListsSuccess({ lists })),
                    catchError((error) =>
                        of(listsActions.getListsFailure({ error }))
                    )
                )
            )
        )
    );

    getListForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.getListForId),
            switchMap((action) =>
                this.listsService.getListForId(action.listId).pipe(
                    map((list) => listsActions.getListForIdSuccess({ list })),
                    catchError((error) =>
                        of(listsActions.getListForIdFailure({ error }))
                    )
                )
            )
        )
    );

    getMembersForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.getMembersForId),
            switchMap((action) =>
                this.listsService.getMembersForId(action.listId).pipe(
                    map((members) =>
                        listsActions.getMembersForIdSuccess({
                            listId: action.listId,
                            members,
                        })
                    ),
                    catchError((error) =>
                        of(
                            listsActions.getMembersForIdFailure({
                                listId: action.listId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getPartiesForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.getPartiesForId),
            switchMap((action) =>
                this.listsService.getPartiesForId(action.listId).pipe(
                    map((parties) =>
                        listsActions.getPartiesForIdSuccess({
                            listId: action.listId,
                            parties,
                        })
                    ),
                    catchError((error) =>
                        of(
                            listsActions.getPartiesForIdFailure({
                                listId: action.listId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getPartyRolesForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.getPartyRolesForId),
            switchMap((action) =>
                this.listsService.getPartyRolesForId(action.listId).pipe(
                    map((partyRoles) =>
                        listsActions.getPartyRolesForIdSuccess({
                            listId: action.listId,
                            partyRoles,
                        })
                    ),
                    catchError((error) =>
                        of(
                            listsActions.getPartyRolesForIdFailure({
                                listId: action.listId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteEntityFromList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.deleteEntityFromList),
            switchMap((action) =>
                this.listsService
                    .deleteEntityFromList(
                        action.listId,
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((_) =>
                            listsActions.deleteEntityFromListSuccess({
                                listId: action.listId,
                                entityType: action.entityType,
                                entityId: action.entityId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                listsActions.deleteEntityFromListFailure({
                                    listId: action.listId,
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addListResultWithQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.addListResultWithQuery),
            mergeMap((action) =>
                this.listsService
                    .addListResultsWithQuery(
                        action.listId,
                        action.entityName,
                        action.query
                    )
                    .pipe(
                        map(() =>
                            listsActions.addListResultWithQuerySuccess({
                                listId: action.listId,
                                entityName: action.entityName,
                                query: action.query,
                            })
                        ),
                        catchError((error) =>
                            of(
                                listsActions.addListResultWithQueryError({
                                    listId: action.listId,
                                    entityName: action.entityName,
                                    query: action.query,
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getListsByType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listsActions.getListsByType),
            switchMap((action) =>
                this.listsService.getListsByType(action.listType).pipe(
                    map((lists) =>
                        listsActions.getListByTypeSuccess({
                            listType: action.listType,
                            lists,
                        })
                    ),
                    catchError((error) =>
                        of(
                            listsActions.getListByTypeFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
