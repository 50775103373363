export enum FormFrameworkEventType {
    Dirty,
    SaveStart,
    SaveFailed,
    SaveClicked,
    SaveSuccess,
    FormLoaded,
    FormValueChanged,
    Touched,
}

export interface FormFrameworkEventAction {
    dirty?: boolean;
    pristine?: boolean;
    touched?: boolean;
    formValue?: Record<string, any>;
    valid?: boolean;
    event?: Event;
}

export interface FormFrameworkEvent {
    type: FormFrameworkEventType;
    action?: FormFrameworkEventAction;
}

export class FormFrameworkFullDataEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.FormValueChanged;
    action: FormFrameworkEventAction;

    constructor(
        type: FormFrameworkEventType,
        action: FormFrameworkEventAction,
    ) {
        this.type = type;
        this.action = action;
    }
}

export class FormFrameworkFormValueChangedEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.FormValueChanged, action);
    }
}

export class FormFrameworkFormTouchedEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.Touched, action);
    }
}

export class FormFrameworkSaveStartEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveStart;
}

export class FormFrameworkSaveFailedEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveFailed;
}

export class FormFrameworkSaveSuccessEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveSuccess;
}

export class FormFrameworkLoadedEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.FormLoaded;
}
