import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { RoutableSystemEntity } from '@wdx/clmi/utils/models';
import {
    ADMIN_ENTITY_ROUTE_MAP,
    ENTITY_LISTING_MAP,
    ENTITY_ROUTE_MAP,
    PipelineActionEntity,
    ROUTE_PREFIX,
    ROUTE_PREFIX_ADMINISTRATION,
    SystemEntity,
    URL_EXTERNAL_TASKS,
    URL_INBOX,
    URL_TASKS,
} from '@wdx/shared/utils';

@Injectable({
    providedIn: 'root',
})
export class EntityRouteService {
    private location = inject(Location);

    routeForEntity(
        entityType: RoutableSystemEntity,
        entityId?: string | number,
        regardingId?: string | number,
        subEntityType?: RoutableSystemEntity,
        subEntityId?: string | number,
    ): string[] | null {
        const ROUTE_FRAGMENT = this.getEntityRoute(entityType, entityId);

        if (!entityType) {
            return [];
        }

        if (!ROUTE_FRAGMENT) {
            return entityType !== SystemEntity.User ? [] : null;
        }

        return [
            ...(ROUTE_FRAGMENT || []),
            ...(regardingId ? [String(regardingId)] : []),
            ...(entityId ? [String(entityId)] : []),
            ...(subEntityType
                ? this.getEntityRoute(subEntityType, subEntityId, true)
                : []),
            ...(subEntityId ? [String(subEntityId)] : []),
        ];
    }

    routeWithParamsForEntity(
        entityType: RoutableSystemEntity,
        entityId?: string | number,
        regardingId?: string | number,
        subEntityType?: RoutableSystemEntity,
        subEntityId?: string | number,
    ): {
        routerLink: string[] | null;
        queryParams: Params | null;
    } {
        let routerLink: string[] | null;
        let queryParams = {};

        if (entityType === SystemEntity.WorkItem) {
            routerLink = this.routeForEntity(
                SystemEntity.Case,
                entityId,
                regardingId,
                subEntityType,
                subEntityId,
            );
        } else if (
            [URL_INBOX, URL_TASKS, URL_EXTERNAL_TASKS].includes(
                this.mapEntityToUrl(entityType),
            )
        ) {
            routerLink = this.routeForEntity(entityType);
            queryParams = {
                activityId: entityId,
            };
        } else {
            routerLink = this.routeForEntity(
                entityType,
                entityId,
                regardingId,
                subEntityType,
                subEntityId,
            );
        }

        return {
            routerLink,
            queryParams,
        };
    }

    /**
     * Returns the relevant route fragment
     * - If the entityType could be inside admin (e.g. user or team) returns the relevant route from ADMIN_ENTITY_ROUTE_MAP
     * - If the entityType is Opportunity pass in custom entity if a single item
     * - For all other types return standard item from ENTITY_ROUTE_MAP
     * @param entityType
     * @param entityId
     */
    private getEntityRoute(
        entityType: RoutableSystemEntity,
        entityId?: string | number,
        isSubEntity = false,
    ): string[] {
        const adminRouteMapIndex = Object.keys(ADMIN_ENTITY_ROUTE_MAP).indexOf(
            entityType,
        );
        if (adminRouteMapIndex > -1 && this.isAdminLocation()) {
            return [
                ...ROUTE_PREFIX,
                ...Object.values(ADMIN_ENTITY_ROUTE_MAP)[adminRouteMapIndex],
            ];
        }

        if (entityType === SystemEntity.Opportunity && entityId) {
            return ENTITY_ROUTE_MAP[PipelineActionEntity.OpportunityItem]
                ? [
                      ...ROUTE_PREFIX,
                      ...ENTITY_ROUTE_MAP[PipelineActionEntity.OpportunityItem],
                  ]
                : ENTITY_ROUTE_MAP[PipelineActionEntity.OpportunityItem];
        }

        const ENTITY_ROUTE = this.mapEntityToUrl(entityType);
        const LISTING_ROUTE = this.mapEntityToListingUrl(entityType);

        const ROUTE = LISTING_ROUTE && !entityId ? LISTING_ROUTE : ENTITY_ROUTE;

        return ROUTE ? [...(isSubEntity ? [] : ROUTE_PREFIX), ...ROUTE] : ROUTE;
    }

    private mapEntityToUrl(entity: RoutableSystemEntity): string[] {
        return ENTITY_ROUTE_MAP[entity];
    }

    /**
     * Returns a listing page is the entity is associated with one - null otherwise
     */
    private mapEntityToListingUrl(
        entity: RoutableSystemEntity,
    ): string[] | null {
        return ENTITY_LISTING_MAP[entity] ?? null;
    }

    /**
     * Determines if the current location is inside system admin.
     */
    private isAdminLocation(): boolean {
        return this.location
            ?.path()
            .includes(ROUTE_PREFIX_ADMINISTRATION.join('/'));
    }
}
