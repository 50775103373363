import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SystemEntity } from '@wdx/clmi/api-models';
import { operationsActions } from '@wdx/clmi/api-services/state';
import { SignalrEvent } from '@wdx/shared/utils';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as casesActions from './cases.actions';
import { CasesService } from './cases.service';

@Injectable()
export class CasesEffects {
    private actions$ = inject(Actions);
    private casesService = inject(CasesService);

    getCases$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getCases),
            switchMap((action) =>
                this.casesService
                    .getCases(action.entityType, action.entityId)
                    .pipe(
                        map((cases) =>
                            casesActions.getCasesSuccess({
                                entityId: action.entityId,
                                cases,
                            })
                        ),
                        catchError((error) =>
                            of(
                                casesActions.getCasesFailure({
                                    entityId: action.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getCase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getCase),
            switchMap((action) =>
                this.casesService.getCase(action.caseId).pipe(
                    map((targetCase) =>
                        casesActions.getCaseSuccess({
                            caseId: action.caseId,
                            case: targetCase,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getCaseFailure({
                                caseId: action.caseId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getCaseWork$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getCaseWork),
            switchMap((action) =>
                this.casesService.getCaseWork(action.caseId).pipe(
                    map((caseWork) =>
                        casesActions.getCaseWorkSuccess({
                            caseWork,
                            caseId: action.caseId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getCaseWorkFailure({
                                caseId: action.caseId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getCaseHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getCaseHistory),
            switchMap((action) =>
                this.casesService.getCaseHistory(action.caseId).pipe(
                    map((caseHistory) =>
                        casesActions.getCaseHistorySuccess({
                            caseHistory,
                            caseId: action.caseId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getCaseHistoryFailure({
                                caseId: action.caseId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getCasesForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getCasesForClient),
            switchMap((action) =>
                this.casesService.getCasesForClient(action.clientId).pipe(
                    map((clientCases) =>
                        casesActions.getCasesForClientSuccess({
                            clientCases,
                            clientId: action.clientId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getCasesForClientFailure({
                                clientId: action.clientId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getFavourites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getFavourites),
            switchMap(() =>
                this.casesService.getFavourites().pipe(
                    map((cases) =>
                        casesActions.getFavouritesSuccess({ favourites: cases })
                    ),
                    catchError((error) =>
                        of(casesActions.getFavouritesFailure({ error }))
                    )
                )
            )
        )
    );

    deleteTeamMemberFromCase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.deleteTeamMember),
            mergeMap((action) =>
                this.casesService
                    .deleteTeamMemberFromCase(action.caseId, action.memberId)
                    .pipe(
                        map(() =>
                            casesActions.deleteTeamMemberSuccess({
                                caseId: action.caseId,
                                memberId: action.memberId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                casesActions.deleteTeamMemberFailure({
                                    caseId: action.caseId,
                                    memberId: action.memberId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getAllMembersForCase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getAllMembers),
            switchMap((action) =>
                this.casesService.getAllMembersForCase(action.caseId).pipe(
                    map((teamMembers) =>
                        casesActions.getAllMembersSuccess({
                            caseId: action.caseId,
                            teamMembers,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getAllMembersFailure({
                                caseId: action.caseId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getCompletedCase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.completeCase),
            switchMap((action) =>
                this.casesService.completeCase(action.caseId).pipe(
                    map((targetCase) =>
                        casesActions.completeCaseSuccess({
                            caseId: action.caseId,
                            case: targetCase,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.completeCaseFailure({
                                caseId: action.caseId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    completedCaseSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.completeCaseSuccess),
            map((action) =>
                operationsActions.getOperationsForId({
                    entityType: SystemEntity.Case,
                    entityId: action.caseId,
                })
            )
        )
    );

    cancelCase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.cancelCase),
            switchMap((action) =>
                this.casesService.cancelCase(action.caseId).pipe(
                    map((targetCase) =>
                        casesActions.cancelCaseSuccess({
                            caseId: action.caseId,
                            case: targetCase,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.cancelCaseFailure({
                                caseId: action.caseId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    cancelCaseSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.cancelCaseSuccess),
            map((action) =>
                operationsActions.getOperationsForId({
                    entityType: SystemEntity.Case,
                    entityId: action.caseId,
                })
            )
        )
    );

    getCaseCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getCaseCategories),
            switchMap((action) =>
                this.casesService.getCategoriesForCase(action.caseId).pipe(
                    map((categories) =>
                        casesActions.getCaseCategoriesSuccess({
                            caseId: action.caseId,
                            categories,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getCaseCategoriesFailure({
                                caseId: action.caseId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    listenToCaseUpdatedEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    // TODO(sentry): Could not automatically migrate - see https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
                    hub
                        .on(SignalrEvent.CaseUpdated)
                        .pipe(
                            map((caseId: string) =>
                                casesActions.caseUpdated({ caseId: caseId })
                            )
                        )
                )
            )
        )
    );

    listenToCaseReadyEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    // TODO(sentry): Could not automatically migrate - see https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
                    hub
                        .on(SignalrEvent.CaseReady)
                        .pipe(
                            map((caseId: string) =>
                                casesActions.caseReady({ caseId: caseId })
                            )
                        )
                )
            )
        )
    );

    getVisibilityForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getVisibilityForId),
            mergeMap((action) =>
                this.casesService.getVisibilityForId(action.id).pipe(
                    map((visibility) =>
                        casesActions.getVisibilityForIdSuccess({
                            id: action.id,
                            visibility,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getVisibilityForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getReviewsForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getReviewsForId),
            mergeMap((action) =>
                this.casesService.getReviewsForId(action.id).pipe(
                    map((reviews) =>
                        casesActions.getReviewsForIdSuccess({
                            id: action.id,
                            reviews,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getReviewsForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getKycHeatmap$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getKycHeatmap),
            mergeMap((action) =>
                this.casesService.getKycHeatmap(action.id).pipe(
                    map((heatMap) =>
                        casesActions.getKycHeatmapSuccess({
                            id: action.id,
                            heatMap,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getKycHeatmapFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getCaseHeatmap$ = createEffect(() =>
        this.actions$.pipe(
            ofType(casesActions.getCaseHeatmap),
            mergeMap((action) =>
                this.casesService.getCaseHeatmap(action.id).pipe(
                    map((heatMap) =>
                        casesActions.getCaseHeatmapSuccess({
                            id: action.id,
                            heatMap,
                        })
                    ),
                    catchError((error) =>
                        of(
                            casesActions.getCaseHeatmapFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
