import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { FunctionPipe } from '@wdx/shared/utils';
import { MoleculeInfoCardSearchModule } from '../../../components/molecules/molecule-info-card-search/molecule-info-card-search.module';
import { OrganismActivityTemplateSearchComponentDumb } from './components/organism-activity-template-search-dumb/organism-activity-template-search-dumb.component';
import { OrganismActivityTemplateSearchComponent } from './containers/organism-activity-template-search/organism-activity-template-search.component';

@NgModule({
    imports: [
        CommonModule,
        MoleculeInfoCardSearchModule,
        PushPipe,
        FunctionPipe,
    ],
    declarations: [
        OrganismActivityTemplateSearchComponent,
        OrganismActivityTemplateSearchComponentDumb,
    ],
    exports: [
        OrganismActivityTemplateSearchComponent,
        OrganismActivityTemplateSearchComponentDumb,
    ],
})
export class OrganismActivityTemplateSearchModule {}
