import { createAction, props } from '@ngrx/store';
import { ImportCategory } from '@wdx/clmi/api-models';

export const getImportEntities = createAction('[Import] Get Import Entities');
export const getImportEntitiesSuccess = createAction(
    '[Import] Get Import Entities Success',
    props<{
        imports: ImportCategory[];
    }>(),
);
export const getImportEntitiesFailure = createAction(
    '[Import] Get Import Entities Failure',
    props<{
        error: Error;
    }>(),
);

export const importCompleted = createAction('[Import] Import Completed');
