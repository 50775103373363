<div
    *ngIf="openIndexes | fn: getOpenIndexIds : instanceId as openIndexIds"
    ngbAccordion
    #accordion="ngbAccordion"
    class="accordion"
    [class.accordion-flush]="flush"
    [ngClass]="customAccordionClass"
    [closeOthers]="closeOthers"
    [destroyOnHide]="destroyOnHide"
    (hide)="onPanelChange($event, true)"
    (show)="onPanelChange($event, false)"
>
    <ng-container
        *ngFor="let panel of panels; let first = first; let i = index"
    >
        <div
            *ngIf="i | fn: getPanelId : instanceId : panelIds; let panelId"
            [ngbAccordionItem]="panelId"
            [ngClass]="
                accordion.isExpanded(panelId)
                    | fn: setCardClass : highlightOpenPanels : customCardClass
            "
            [collapsed]="!openIndexIds.includes(panelId)"
            [disabled]="panel.isDisabled || disabled"
        >
            <div ngbAccordionHeader>
                <button
                    ngbAccordionButton
                    class="accordion-header accordion-button"
                    [class.disabled]="panel.isDisabled || disabled"
                    (click)="onPanelToggleClicked(panel)"
                >
                    <wdx-icon
                        *ngIf="panel.guidanceText"
                        class="guidance-icon text-muted pointer"
                        icon="question-circle"
                        scale="1"
                        container="body"
                        popoverClass="popover-info"
                        [ngbPopover]="panel.guidanceText"
                        (click)="$event.stopPropagation()"
                    ></wdx-icon>
                    <wdx-icon
                        *ngIf="panel.icon"
                        class="me-2 flex-shrink-0 icon-shift-up"
                        [ngClass]="panel.severity"
                        [icon]="panel.icon"
                    ></wdx-icon>
                    <div
                        *ngIf="panel.label || panel.contact"
                        class="accordion-header__content flex-grow-1 d-flex flex-column flex-xl-row"
                    >
                        <div>
                            {{ panel.label }}
                        </div>
                        <div *ngIf="panel.contact?.name" class="ms-0 ms-xl-2">
                            <a [routerLink]="panel.contact.routerLink">{{
                                panel.contact.name
                            }}</a>
                        </div>
                    </div>
                    <div
                        *ngIf="!panel.headerTemplate"
                        class="d-flex flex-column-reverse flex-xl-row"
                    >
                        <div
                            class="d-flex align-items-center justify-content-end flex-nowrap"
                        >
                            <div class="ms-1 ms-xl-2">
                                <div class="d-none d-xl-block">
                                    <molecule-avatar-group
                                        *ngIf="panel.avatars"
                                        size="sm"
                                        [avatars]="panel.avatars"
                                    ></molecule-avatar-group>
                                </div>
                                <div class="d-block d-xl-none">
                                    <molecule-avatar-group
                                        *ngIf="panel.avatars"
                                        size="xs"
                                        [avatars]="panel.avatars"
                                    ></molecule-avatar-group>
                                </div>
                            </div>
                            <atom-comment-status
                                *ngIf="panel.comments"
                                class="ms-1 ms-xl-2"
                                [commentStatus]="panel.comments"
                            ></atom-comment-status>
                        </div>
                        <div
                            *ngIf="panel.detail !== undefined"
                            class="accordion-header__detail ms-l ms-xl-2"
                            [ngClass]="panel | fn: getStatusSeverityClass"
                        >
                            {{ panel.detail }}
                        </div>
                    </div>
                    <ng-container
                        *ngTemplateOutlet="
                            panel.headerTemplate;
                            context: { data: panel.headerData }
                        "
                    ></ng-container>
                </button>
            </div>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-container
                        *ngTemplateOutlet="
                            panel.template;
                            context: { data: panel.templateData }
                        "
                    ></ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
