import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';
// eslint-disable-next-line max-len
import {
    CashMovement,
    CashSummary,
    Performance,
    PerformancePeriod,
    SecurityPosition,
    SystemEntity,
    ValuationHistory,
    ValuationLevel,
} from '@wdx/clmi/api-models';
import { KeyValuePoint } from '../../models/investment-data.model';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

@Injectable()
export class InvestmentDataService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getValuationHistoryForClientProduct(
        clientProductId: string
    ): Observable<ValuationHistory> {
        return this.http.get<ValuationHistory>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentdata/clientproduct/${clientProductId}/valuationhistory`
        );
    }

    getPerformanceForClientProduct(
        clientProductId: string,
        period: PerformancePeriod
    ): Observable<Performance> {
        return this.http.get<Performance>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentdata/clientproduct/${clientProductId}/performance/${period}`
        );
    }

    getAssetAllocationByCountryForClientProduct(
        clientProductId: string
    ): Observable<KeyValuePoint[]> {
        return this.http.get<KeyValuePoint[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentdata/clientproduct/${clientProductId}/valuation/country?maxSegments=8`
        );
    }

    getAssetAllocationByGeographicForClientProduct(
        clientProductId: string
    ): Observable<KeyValuePoint[]> {
        return this.http.get<KeyValuePoint[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentdata/clientproduct/${clientProductId}/valuation/geographic?maxSegments=8`
        );
    }

    getAssetAllocationBySectorForClientProduct(
        clientProductId: string
    ): Observable<KeyValuePoint[]> {
        return this.http.get<KeyValuePoint[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentdata/clientproduct/${clientProductId}/valuation/sector?maxSegments=8`
        );
    }

    getAssetAllocationByAssetClassForClientProduct(
        clientProductId: string
    ): Observable<KeyValuePoint[]> {
        return this.http.get<KeyValuePoint[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentdata/clientproduct/${clientProductId}/valuation/assetclass?maxSegments=8`
        );
    }

    getMovements(
        paginationOptions: PaginationOptions,
        valuationLevel: ValuationLevel,
        valuationEntityId: string
    ): Observable<PaginatedApiResponse<CashMovement>> {
        return this.http.get<PaginatedApiResponse<CashMovement>>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentdata/${valuationLevel}/${valuationEntityId}/movements`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    getCashSummary(
        valuationLevel: ValuationLevel,
        valuationEntityId: string
    ): Observable<CashSummary> {
        return this.http.get<CashSummary>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentdata/${valuationLevel}/${valuationEntityId}/cashsummary`
        );
    }

    getTopMovers(clientProductId: string): Observable<SecurityPosition[]> {
        return this.http.get<SecurityPosition[]>(
            `${this.config.getConfiguration().API_BASE}/investmentdata/${
                SystemEntity.ClientProduct
            }/${clientProductId}/topmovers`
        );
    }
}
