import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IGetConfig,
    Query,
    SystemEntity,
    ActivityQueryType,
    UserQueryType,
    EXPRESSIONS_LOOP,
    PaginatedApiResponse,
} from '@wdx/shared/utils';
import { ApiPaginationService } from '../pagination/api-pagination.service';
import { PaginationOptions } from '../pagination/pagination-options';

const QUERY = 'query';
@Injectable({
    providedIn: 'root',
})
export class FilterHttpService {
    constructor(
        private http: HttpClient,
        private config: IGetConfig,
        private apiPaginationService: ApiPaginationService
    ) {}

    getRecords<U>(
        queryType: SystemEntity | ActivityQueryType | UserQueryType,
        paginationOptions: PaginationOptions,
        filters: Query
    ): Observable<PaginatedApiResponse<U>> {
        const NEW_FILTERS = EXPRESSIONS_LOOP(filters);

        return this.http.post<PaginatedApiResponse<U>>(
            `${
                this.config.getConfiguration().API_BASE
            }/${QUERY}/${queryType.toLowerCase()}`,
            NEW_FILTERS || {},
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }
}
