import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    NgbDropdownModule,
    NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PushPipe } from '@ngrx/component';
import { EntityRoutePipe, PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { FunctionPipe, TranslatePipe } from '@wdx/shared/utils';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeActivityHeaderModule } from '../../../components/molecules/molecule-activity-header/molecule-activity-header.module';
import { MoleculeAvatarModule } from '../../../components/molecules/molecule-avatar/molecule-avatar.module';
import { MoleculeContextMenuModule } from '../../../components/molecules/molecule-context-menu/molecule-context-menu.module';
import { MoleculeInfoCardSearchModule } from '../../../components/molecules/molecule-info-card-search/molecule-info-card-search.module';
import { OrganismActivityTemplateSearchModule } from '../../../components/organisms/organism-activity-template-search/organism-activity-template-search.module';
import { OrganismCaseTemplateSearchModule } from '../../../components/organisms/organism-case-template-search/organism-case-template-search.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { OrganismArticlesListModule } from '../../../features/articles/shared/components/organism-articles-list/organism-articles-list.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { FormSummaryModule } from '../../../shared/features/form-summary/form-summary.module';
import { RelatedRecordsModule } from '../../../shared/features/related-records/related-records.module';
import { OrganismEngagementBarComponentDumb } from './components/organism-engagement-bar-dumb/organism-engagement-bar-dumb.component';
import { OrganismEngagementBarComponent } from './containers/organism-engagement-bar/organism-engagement-bar.component';

@NgModule({
    imports: [
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        EntityRoutePipe,
        FormSummaryModule,
        MoleculeActivityHeaderModule,
        MoleculeAvatarModule,
        MoleculeContextMenuModule,
        MoleculeInfoCardSearchModule,
        NgbDropdownModule,
        OrganismActivityTemplateSearchModule,
        OrganismArticlesListModule,
        OrganismCaseTemplateSearchModule,
        OrganismModalModule,
        PipesModule,
        PushPipe,
        RelatedRecordsModule,
        RouterModule,
        NgbPopoverModule,
        WdxIconModule,
        PartyToAvatarPipe,
        FunctionPipe,
        TranslatePipe,
    ],
    declarations: [
        OrganismEngagementBarComponent,
        OrganismEngagementBarComponentDumb,
    ],
    exports: [
        OrganismEngagementBarComponent,
        OrganismEngagementBarComponentDumb,
    ],
})
export class OrganismEngagementBarModule {}
