import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { FunctionPipe } from '@wdx/shared/utils';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { AtomTabModule } from '../../../components/atoms/atom-tab/atom-tab.module';
import { MoleculeInfoCardModule } from '../../../components/molecules/molecule-info-card/molecule-info-card.module';
import { MoleculeTabsModule } from '../../../components/molecules/molecule-tabs/molecule-tabs.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeEngagementsDumbComponent } from './components/molecule-engagements-dumb/molecule-engagements-dumb.component';
import { MoleculeEngagementsComponent } from './containers/molecule-engagements/molecule-engagements.component';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomModalWrapperModule,
        AtomTabModule,
        CommonModule,
        DirectivesModule,
        MoleculeInfoCardModule,
        MoleculeTabsModule,
        OrganismModalModule,
        PipesModule,
        PushPipe,
        WdxIconModule,
        FunctionPipe,
    ],
    declarations: [
        MoleculeEngagementsComponent,
        MoleculeEngagementsDumbComponent,
    ],
    exports: [MoleculeEngagementsComponent, MoleculeEngagementsDumbComponent],
})
export class MoleculeEngagementsModule {}
