import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FilterHttpService } from '@wdx/shared/infrastructure/api-service';
import { QueryApiService } from '@wdx/clmi/api-services/services';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as filterActions from '../actions';
import {
    FILTER_QUERY_BATCH,
    FILTER_QUERY_SEARCH,
} from '@wdx/clmi/api-services/models';

@Injectable()
export class FilterEffects<U> {
    private actions$ = inject(Actions);
    private filterHttpService = inject(FilterHttpService);
    private queryApiService = inject(QueryApiService);

    getFilterResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filterActions.getFilterResults),
            switchMap((action) => this.getFilterData(action))
        )
    );

    getFilterResultsMergeMap$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filterActions.getFilterResultsMergeMap),
            mergeMap((action) => this.getFilterData(action))
        )
    );

    addFilterResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filterActions.updateFilterResults),
            switchMap((action) =>
                this.filterHttpService
                    .getRecords(
                        action.queryType,
                        action.paginationOptions,
                        action.filters
                    )
                    .pipe(
                        map((payload) =>
                            filterActions.updateFilterResultsSuccess({
                                payload,
                                selectType: action.selectType,
                            })
                        ),
                        catchError((error) =>
                            of(
                                filterActions.updateFilterResultsFailure({
                                    error,
                                    selectType: action.selectType,
                                })
                            )
                        )
                    )
            )
        )
    );

    getFilterDefinition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filterActions.getFilterDefinition),
            switchMap((action) =>
                this.queryApiService.getQueryDefinition(action.queryType).pipe(
                    map((results) => {
                        const PROCESSED_RESULTS = results;

                        /**
                         * Removing the filter definition with the name Search.
                         * This filter definition is used in the search bar above
                         * the table. It search name and the users id and other fields.
                         * This is more like a wildcard query
                         */
                        results.fieldDefinitions =
                            PROCESSED_RESULTS.fieldDefinitions?.filter(
                                (res) =>
                                    res.name?.toLowerCase() !==
                                        FILTER_QUERY_SEARCH.toLowerCase() &&
                                    res.name?.toLowerCase() !==
                                        FILTER_QUERY_BATCH.toLowerCase()
                            );

                        return results;
                    }),
                    map((results) =>
                        filterActions.getFilterDefinitionSuccess({
                            payload: results,
                            queryType: action.queryType,
                        })
                    ),
                    catchError((error) =>
                        of(
                            filterActions.getFilterDefinitionFailure({
                                error,
                                queryType: action.queryType,
                            })
                        )
                    )
                )
            )
        )
    );

    getFilterData(action: any): Observable<any> {
        return this.filterHttpService
            .getRecords<U>(
                action.queryType,
                action.paginationOptions,
                action.filters
            )
            .pipe(
                map((results) =>
                    filterActions.getFilterResultsSuccess({
                        payload: results,
                        selectType: action.selectType,
                    })
                ),
                catchError((error) =>
                    of(
                        filterActions.getFilterResultsFailure({
                            error,
                            selectType: action.selectType,
                        })
                    )
                )
            );
    }
}
