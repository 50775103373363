import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';

import { AccordionPanelChangeEvent, Icon, Severity } from '@wdx/shared/utils';
import { AccordionPanel } from '../../../models/accordion-panel.model';
import { Direction } from '../../../models/direction.model';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-accordion',
    templateUrl: './molecule-accordion.component.html',
})
export class MoleculeAccordionComponent implements OnInit, OnChanges {
    @Input() panels: AccordionPanel[];
    @Input() closeOthers = true;
    @Input() openFirstPanelByDefault = true;
    @Input() openIndexes: number[];
    @Input() destroyOnHide = true;
    @Input() highlightOpenPanels = false;
    @Input() chevronOpenDirection: Direction = Direction.Up;
    @Input() flush: boolean;
    @Input() customAccordionClass: string;
    @Input() customCardClass: string;
    @Input() customChevronIcon: Icon | string;
    @Input() customCompletionProgressionLabel: string;
    @Input() customCompletedLabel: string;
    @Input() disabled: boolean;

    @Output() panelChange: EventEmitter<AccordionPanelChangeEvent> =
        new EventEmitter<AccordionPanelChangeEvent>();
    @Output() panelToggleClicked = new EventEmitter<AccordionPanel>();

    @ViewChild('accordion', { read: NgbAccordionDirective })
    accordion: NgbAccordionDirective;

    instanceId: string;
    panelIds: string[] = [];

    readonly DIRECTION = Direction;

    ngOnInit() {
        this.instanceId = new RandomStringPipe().transform();
        this.openIndexes = this.openIndexes?.length
            ? this.openIndexes
            : this.openFirstPanelByDefault
              ? [0]
              : [];
    }

    ngOnChanges() {
        if (this.accordion && this.disabled) {
            this.accordion.collapseAll();
        }
    }

    setCardClass(
        isExpanded: boolean,
        highlightOpenPanels: boolean,
        customClass: string,
    ): string {
        return `${
            isExpanded && highlightOpenPanels && 'highlight-open-panel'
        } ${customClass}`;
    }

    getOpenIndexIds(openIndexes: number[], instanceId: string): string[] {
        return (
            openIndexes?.map((index) => `panel-${instanceId}-${index}`) ?? []
        );
    }

    getPanelId(
        panelIndex: number,
        instanceId: string,
        panelIds: string[],
    ): string {
        const id = `panel-${instanceId}-${panelIndex}`;
        if (panelIds.indexOf(id) < 0) {
            panelIds.push(id);
        }

        return `panel-${instanceId}-${panelIndex}`;
    }

    getStatusSeverityClass(panel: AccordionPanel): string {
        switch (panel.severity) {
            case Severity.Success:
                return 'success-text';
            case Severity.Danger:
                return 'danger-text';
            case Severity.Warning:
                return 'warning-text';
            case Severity.None:
                return null;

            default:
                return 'text-muted';
        }
    }

    onPanelToggleClicked(panel: AccordionPanel) {
        this.panelToggleClicked.emit(panel);
    }

    onPanelChange(panelId: string, collapsed: boolean) {
        this.panelChange.emit({
            index: this.panelIds.indexOf(panelId),
            panelId,
            collapsed,
        });
    }
}
