import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ImportCategory } from '@wdx/clmi/api-models';
import {
    importEntitiesActions,
    importEntitiesSelectors,
} from '@wdx/clmi/api-services/state';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ImportEntitiesFacadeService {
    importEntities$: Observable<ImportCategory[]>;
    importEntitiesIsLoading$: Observable<boolean>;
    importEntitiesHasError$: Observable<boolean>;

    systemEntityId: string;

    constructor(private store$: Store) {}

    setImportEntitiesList(): void {
        this.importEntities$ = this.store$.select(
            importEntitiesSelectors.getImportEntities(),
        );

        this.importEntitiesIsLoading$ = this.store$.select(
            importEntitiesSelectors.getImportEntitiesIsLoading(),
        );
        this.importEntitiesHasError$ = this.store$.select(
            importEntitiesSelectors.getImportEntitiesHasLoadingError(),
        );
    }

    getImportEntities(): any {
        this.store$.dispatch(importEntitiesActions.getImportEntities());
    }
}
