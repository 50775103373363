import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { WdxButtonSize } from '@wdx/shared/components/wdx-button';

export interface WdxRadioButtonGroupConfig {
    label: string;
    value: string;
    icon?: string;
}

@Component({
    selector: 'wdx-radio-button-group',
    templateUrl: './wdx-radio-button-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxRadioButtonGroupComponent {
    @Input() buttonsConfig: WdxRadioButtonGroupConfig[] = [];
    @Input() checkedValue?: string;
    @Input() disabled = false;
    @Input() size: WdxButtonSize = WdxButtonSize.Normal;

    @Output() wdxClicked = new EventEmitter<string>();

    public selected(value: string) {
        this.checkedValue = value;
        this.wdxClicked.emit(value);
    }
}
