import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Client,
    HeatMap,
    KeyStatResult,
    PartyRoleRelationship,
    ProductType,
    RelationshipTypeSimple,
} from '@wdx/clmi/api-models';
import { HttpClientGetJsonOptions } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ClientsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getClient(
        clientId: string,
        options: HttpClientGetJsonOptions = {}
    ): Observable<Client> {
        return this.http.get<Client>(
            `${this.config.getConfiguration().API_BASE}/client/${clientId}`,
            options
        );
    }

    getFavourites(): Observable<Client[]> {
        return this.http.get<Client[]>(
            `${this.config.getConfiguration().API_BASE}/client/favourite`
        );
    }

    getAccountHoldersForClient(
        clientId: string
    ): Observable<PartyRoleRelationship[]> {
        return this.http.get<PartyRoleRelationship[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/accountholder`
        );
    }

    getOtherPartiesForClient(
        clientId: string
    ): Observable<PartyRoleRelationship[]> {
        return this.http.get<PartyRoleRelationship[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/otherparty`
        );
    }

    getApplicableRolesForClient(
        clientId: string
    ): Observable<RelationshipTypeSimple[]> {
        return this.http.get<RelationshipTypeSimple[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/applicableRoles`
        );
    }

    getPartiesForClient(clientId: string): Observable<PartyRoleRelationship[]> {
        return this.http.get<PartyRoleRelationship[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/roles`
        );
    }

    getKeyStatsForClient(clientId: string): Observable<KeyStatResult[]> {
        return this.http.get<KeyStatResult[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/keystats`
        );
    }

    getHeatMapForClient(clientId: string): Observable<HeatMap> {
        return this.http.get<HeatMap>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/heatmap`
        );
    }

    deleteRelationshipForId(
        relationshipId: string
    ): Observable<PartyRoleRelationship> {
        return this.http.delete<PartyRoleRelationship>(
            `${
                this.config.getConfiguration().API_BASE
            }/relationship/${relationshipId}`
        );
    }

    getClientProductType(clientId: string): Observable<ProductType[]> {
        return this.http.get<ProductType[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/producttypes`
        );
    }
}
