import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromChat from './+state/chat.reducer';
import { ChatEffects } from './+state/chat.effects';
import { ChatFacade } from './+state/chat.facade';
import { CommentService } from '@wdx/clmi/api-services/services';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';

const sharedImports: (any[] | Type<any> | ModuleWithProviders<any>)[] = [
    CommonModule,
];

// to be used by app module only to set up ngrx store slice
// do not add declarations as these need to be in ClmiFeaturesChatFeatureModule which is the
// "safe" module to import anywhere (including app module if needed)
// likely most imports and exports will all want to be in the other module as well
@NgModule({
    imports: [
        ...sharedImports,
        StoreModule.forFeature(fromChat.CHAT_FEATURE_KEY, fromChat.chatReducer),
        EffectsModule.forFeature([ChatEffects]),
    ],
})
export class ClmiFeaturesChatRootModule {}

@NgModule({
    imports: [...sharedImports],
    providers: [ChatFacade, CommentService, ApiPaginationService],
})
export class ClmiFeaturesChatFeatureModule {}
