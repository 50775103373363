import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { ThemeComponentClass } from '@wdx/shared/components/abstract-classes';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { UTILS_ICON_DROPDOWN, WdxThemeColor } from '@wdx/shared/utils';

export type WdxSplitButtonMenuItem = { label: string } & Record<string, any>;

@Component({
    selector: 'wdx-split-button',
    templateUrl: './wdx-split-button.component.html',
    styleUrls: ['./wdx-split-button.component.scss'],
    standalone: true,
    imports: [CommonModule, NgbDropdownModule, WdxIconModule],
})
export class WdxSplitButtonComponent
    extends ThemeComponentClass
    implements OnInit
{
    @Input() isLoading = false;
    @Input() disabled = false;
    @Input() menuItems: WdxSplitButtonMenuItem[] = [];
    @Input() menuPlacement: PlacementArray = [
        'bottom-end',
        'top-end',
        'bottom-start',
        'top-start',
    ];

    @Output() menuItemClicked = new EventEmitter<WdxSplitButtonMenuItem>();

    override themeClassPrefix = 'btn';
    override themeColorDefault = 'primary' as WdxThemeColor;

    readonly ICON_DROPDOWN = UTILS_ICON_DROPDOWN;

    onMenuItemSelected(menuItem: WdxSplitButtonMenuItem): void {
        if (!this.disabled) {
            this.menuItemClicked.emit(menuItem);
        }
    }
}
