import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { TranslateTokenisedStringPipe } from '@wdx/shared/utils';
import { AtomTabModule } from '../../../components/atoms/atom-tab/atom-tab.module';
import { MoleculeTabsDumbComponent } from './molecule-tabs-dumb/molecule-tabs-dumb.component';
import { MoleculeTabsComponent } from './molecule-tabs/molecule-tabs.component';

@NgModule({
    imports: [
        AtomTabModule,
        CommonModule,
        PushPipe,
        RouterModule,
        TranslateTokenisedStringPipe,
    ],
    declarations: [MoleculeTabsDumbComponent, MoleculeTabsComponent],
    exports: [MoleculeTabsDumbComponent, MoleculeTabsComponent],
})
export class MoleculeTabsModule {}
