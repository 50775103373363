import { SystemEntity, View } from '@wdx/clmi/api-models';

import { createAction, props } from '@ngrx/store';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { FilterTypeTypes } from '../../models/filter-type-types.model';

export const getAll = createAction('[Views] Get All');

export const getAllSuccess = createAction(
    '[Views] Get All Success',
    props<{
        views: View[];
    }>(),
);

export const getAllFailure = createAction(
    '[Views] Get All Failure',
    props<{
        error: Error;
    }>(),
);

export const getForEntityType = createAction(
    '[Views] Get for Entity',
    props<{
        entityType: SystemEntity;
        typeCode?: string;
        isContextual?: boolean;
    }>(),
);

export const getForEntityTypeSuccess = createAction(
    '[Views] Get for Entity Success',
    props<{
        entityType: SystemEntity;
        typeCode?: string;
        isContextual?: boolean;
        views: View[];
    }>(),
);

export const getForEntityTypeFailure = createAction(
    '[Views] Get for Entity Failure',
    props<{
        entityType: SystemEntity;
        typeCode?: string;
        isContextual?: boolean;
        error: Error;
    }>(),
);

export const getSingleForEntityType = createAction(
    '[Views] Get for Entity by ID',
    props<{
        entityType: SystemEntity;
        viewId: string;
    }>(),
);

export const getSingleForEntityTypeSuccess = createAction(
    '[Views] Get for Entity by ID Success',
    props<{
        entityType: SystemEntity;
        view: View;
    }>(),
);

export const getSingleForEntityTypeFailure = createAction(
    '[Views] Get for Entity by ID Failure',
    props<{
        entityType: SystemEntity;
        error: Error;
    }>(),
);

export const createByEntityType = createAction(
    '[Views] Create By Entity Type',
    props<{
        entityType: SystemEntity;
        view: View;
    }>(),
);

export const createByEntityTypeSuccess = createAction(
    '[Views] Create By Entity Type Success',
    props<{
        entityType: SystemEntity;
        view: View;
    }>(),
);

export const createByEntityTypeFailure = createAction(
    '[Views] Create By Entity Type Failure',
    props<{
        entityType: SystemEntity;
        error: Error;
    }>(),
);

export const updateByEntityType = createAction(
    '[Views] Update By Entity Type',
    props<{
        entityType: SystemEntity;
        viewId: string;
        view: View;
    }>(),
);

export const updateByEntityTypeSuccess = createAction(
    '[Views] Update By Entity Type Success',
    props<{
        entityType: SystemEntity;
        view: View;
    }>(),
);

export const updateByEntityTypeFailure = createAction(
    '[Views] Update By Entity Type Failure',
    props<{
        entityType: SystemEntity;
        error: Error;
    }>(),
);

export const deleteByEntityType = createAction(
    '[Views] Delete By Entity Type',
    props<{
        entityType: SystemEntity;
        viewId: string;
    }>(),
);

export const deleteByEntityTypeSuccess = createAction(
    '[Views] Delete By Entity Type Success',
    props<{
        entityType: SystemEntity;
        viewId: string;
    }>(),
);

export const deleteByEntityTypeFailure = createAction(
    '[Views] Delete By Entity Type Failure',
    props<{
        entityType: SystemEntity;
        error: Error;
    }>(),
);

export const getViewFilterSearch = createAction(
    '[Views] Get View Filter Search',
    props<{
        viewId: string;
        entityType: SystemEntity;
        filter: any;
        reset?: boolean;
        pageSize: number;
    }>(),
);

export const getViewFilterSearchSuccess = createAction(
    '[Views] Get View Filter Search Success',
    props<{
        viewId: string;
        search: PaginatedApiResponse<FilterTypeTypes>;
    }>(),
);

export const getViewFilterSearchFailure = createAction(
    '[Views] Get View Filter Search Failure',
    props<{
        viewId: string;
        error: Error;
    }>(),
);
