import { createSelector } from '@ngrx/store';
import * as activitiesReducer from './activities.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    activities: activitiesReducer.State;
}): activitiesReducer.State => state.activities;

export const getClientActivitiesIsLoadingPage = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.clientActivities[props.id]?.isLoadingPage,
);
export const getClientActivitiesHasLoadingPageError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.clientActivities[props.id]?.hasLoadingPageError,
);
export const getClientActivitiesInfinityPaging = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.clientActivities[props.id]?.infinity?.paging,
);
export const getClientActivitiesInfinityCombinedList = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.clientActivities[props.id]?.infinity?.combinedList,
);

/**
 * Reducer property selectors
 */

export const getAttachmentsForIdIsLoading = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.attachments[props.id]?.isLoadingList,
);
export const getAttachmentsForIdHasLoadingError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.attachments[props.id]?.hasLoadingListError,
);
export const getAttachmentsForId = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.attachments[props.id]?.list,
);

export const getDocumentsForIdIsLoadingPage = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.documents[props.id]?.isLoadingPage,
);
export const getDocumentsForIdHasLoadingPageError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.documents[props.id]?.hasLoadingPageError,
);
export const getDocumentsForIdInfinityPaging = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.documents[props.id]?.infinity?.paging,
);
export const getDocumentsForIdInfinityCombinedList = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.documents[props.id]?.infinity?.combinedList,
);

export const getLastActivityIsLoading = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.lastActivity[props.id]?.isLoadingSingle,
);
export const getLastActivityHasLoadingError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.lastActivity[props.id]?.hasLoadingSingleError,
);
export const getLastActivity = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.lastActivity[props.id]?.single,
);
export const getNextActivityIsLoading = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.nextActivity[props.id]?.isLoadingSingle,
);
export const getNextActivityHasLoadingError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.nextActivity[props.id]?.hasLoadingSingleError,
);
export const getNextActivity = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.nextActivity[props.id]?.single,
);
export const getIsLoadingPage = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.isLoadingPage,
);
export const getHasLoadingPageError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.hasLoadingPageError,
);
export const getInfinityPaging = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.infinity?.paging,
);
export const getInfinityCombinedList = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.infinity?.combinedList,
);

export const getIsLoadingSingle = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.isLoadingSingle,
);
export const getHasLoadingSingleError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.hasLoadingSingleError,
);
export const getSingle = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.single,
);

export const getActivityHistoryIsLoadingList = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activityHistory[props.id]?.isLoadingList,
);
export const getActivityHistoryHasLoadingListError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activityHistory[props.id]?.hasLoadingListError,
);
export const getActivityHistoryList = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activityHistory[props.id]?.list,
);

export const getIsUpdating = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.isUpdating,
);
export const getHasUpdatingError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.activities[props.id]?.hasUpdatingError,
);

export const getAttachmentUploadStatus = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.attachment[props.id]?.uploadStatus,
);
export const getAttachmentUploadProgress = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.attachment[props.id]?.progress,
);
export const getAttachmentUploadFileIndex = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.attachment[props.id]?.fileIndex,
);
export const getAttachmentUploadError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.attachment[props.id]?.error,
);

export const getVisibilityForIdIsLoadingList = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.visibility[props.id]?.isLoadingList,
);
export const getVisibilityForIdHasLoadingListError = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.visibility[props.id]?.hasLoadingListError,
);
export const getVisibilityForId = createSelector(
    getState,
    (state: activitiesReducer.State, props: any) =>
        state.visibility[props.id]?.list,
);
export const getCrudStatus = createSelector(
    getState,
    (state: activitiesReducer.State) => state.crudStatus,
);
