import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { PushPipe } from '@ngrx/component';
import { WdxAlertModule } from '@wdx/shared/components/wdx-alert';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxIconStackModule } from '@wdx/shared/components/wdx-icon-stack';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxSplitButtonComponent } from '@wdx/shared/components/wdx-split-button';
import {
    IFormDocumentHandler,
    IFormDynamicData,
    IFormExternalActions,
    ISubformCustomHandler,
    IWrappedFormComponentProvider,
    WdxFormFrameworkModule,
} from '@wdx/shared/infrastructure/form-framework';
import {
    IGetConfig,
    IHasPrivilege,
    TranslateTokenisedStringPipe,
    WdxDateTimeService,
    WdxMarkdownToHtmlPipe,
    WdxNumberService,
    WdxSafeHtmlPipe,
} from '@wdx/shared/utils';
import { AtomAsyncButtonModule } from '../../components/atoms/atom-async-button/atom-async-button.module';
import { AtomAsyncDataModule } from '../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomIconButtonModule } from '../../components/atoms/atom-icon-button/atom-icon-button.module';
import { OrganismModalModule } from '../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ConfigService } from '../../services/config.service';
import { FormSummaryModule } from '../../shared/features/form-summary/form-summary.module';
import { FormSummaryPipesModule } from '../../shared/features/form-summary/pipes/form-summary-pipes.module';
import {
    OpenInNewTabService,
    PrivilegesService,
    ReactiveFormDynamicDataService,
} from '../../shared/services';
import { QuickCreateModalComponent } from './components/quick-create-modal/quick-create-modal.component';
import { FormPipesModule } from './pipes/form-pipes.module';
import { FormDocumentHandler } from './services/form-document-handler/form-document-handler.service';
import { FormExternalActionsService } from './services/form-external-actions/form-external-actions.service';
import { WrappedFormControlService } from './services/form-wrapped-control/form-wrapped-control.service';
import { SubformCustomHandlerService } from './services/subform-custom-handler/subform-custom-handler.service';

@NgModule({
    declarations: [QuickCreateModalComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        NgbAccordionModule,
        AtomAsyncButtonModule,
        AtomAsyncDataModule,
        AtomIconButtonModule,
        DirectivesModule,
        FormPipesModule,
        FormSummaryModule,
        OrganismModalModule,
        PipesModule,
        PushPipe,
        WdxBadgeModule,
        WdxIconModule,
        WdxIconButtonModule,
        WdxIconStackModule,
        WdxInfoCardModule,
        WdxSafeHtmlPipe,
        WdxMarkdownToHtmlPipe,
        WdxAlertModule,
        WdxFormFrameworkModule,
        WdxIconContainerModule,
        WdxIsLoadingModule,
        WdxSpinnerModule,
        WdxSplitButtonComponent,
        FormSummaryPipesModule,
        TranslateTokenisedStringPipe,
    ],
    providers: [
        OpenInNewTabService,
        WdxDateTimeService,
        WdxNumberService,
        { provide: IFormExternalActions, useClass: FormExternalActionsService },
        { provide: IGetConfig, useClass: ConfigService },
        { provide: IHasPrivilege, useClass: PrivilegesService },
        {
            provide: IWrappedFormComponentProvider,
            useClass: WrappedFormControlService,
        },
        {
            provide: ISubformCustomHandler,
            useClass: SubformCustomHandlerService,
        },
        { provide: IFormDynamicData, useClass: ReactiveFormDynamicDataService },
        {
            provide: IFormDocumentHandler,
            useClass: FormDocumentHandler,
        },
    ],
})
export class CLMiReactiveFormsModule {}
