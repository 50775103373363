import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpportunityContextualTypes } from '../../models/opportunities.model';
import { ConfigService } from '../../services/config.service';
import {
    AccessVisibility,
    Opportunity,
    OpportunityCreate,
    OpportunityTeamMember,
    PipelineSummary,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { EXPRESSIONS_LOOP } from '@wdx/shared/utils';

@Injectable()
export class OpportunitiesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    moveToStage(
        opportunityId: string,
        stageCode: string
    ): Observable<Opportunity> {
        return this.http.patch<Opportunity>(
            `${
                this.config.getConfiguration().API_BASE
            }/opportunity/${opportunityId}/stage/${stageCode}`,
            {}
        );
    }

    create(
        opportunityCreateData: OpportunityCreate
    ): Observable<OpportunityCreate> {
        return this.http.post<OpportunityCreate>(
            `${this.config.getConfiguration().API_BASE}/opportunity`,
            opportunityCreateData
        );
    }

    deleteOpportunity(opportunityId: string): Observable<any> {
        return this.http.delete<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/opportunity/${opportunityId}`
        );
    }

    getForId(opportunityId: string): Observable<Opportunity> {
        return this.http.get<Opportunity>(
            `${
                this.config.getConfiguration().API_BASE
            }/opportunity/${opportunityId}`
        );
    }

    getPipelineSummary(filters: any): Observable<PipelineSummary> {
        const NEW_FILTERS = EXPRESSIONS_LOOP(filters);

        return this.http.post<PipelineSummary>(
            `${
                this.config.getConfiguration().API_BASE
            }/opportunity/pipelinesummary`,
            NEW_FILTERS
        );
    }

    /** Team members */

    assignTeamMemberToOpportunity(
        opportunityId: string,
        partyId: string
    ): Observable<OpportunityTeamMember> {
        return this.http.patch<OpportunityTeamMember>(
            `${
                this.config.getConfiguration().API_BASE
            }/opportunity/${opportunityId}/member/${partyId}`,
            {}
        );
    }

    getAllMembersForOpportunity(
        opportunityId: string
    ): Observable<OpportunityTeamMember[]> {
        return this.http.get<OpportunityTeamMember[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/opportunity/${opportunityId}/member`
        );
    }

    deleteTeamMemberFromOpportunity(
        opportunityId: string,
        partyId: string
    ): Observable<OpportunityTeamMember> {
        return this.http.delete<OpportunityTeamMember>(
            `${
                this.config.getConfiguration().API_BASE
            }/opportunity/${opportunityId}/member/${partyId}`,
            {}
        );
    }

    getVisibilityForId(id: string): Observable<AccessVisibility[]> {
        return this.http.get<AccessVisibility[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/opportunity/${id}/visibility`
        );
    }

    getContextualOpportunity(
        contextualType: OpportunityContextualTypes,
        contextualID: string
    ): Observable<Opportunity[]> {
        const SYSTEM_ENTITY = this.contextualTypeName(contextualType);

        return this.http.get<Opportunity[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${SYSTEM_ENTITY}/${contextualID}/${SystemEntity.Opportunity.toLowerCase()}`,
            {}
        );
    }

    private contextualTypeName(
        contextualType: OpportunityContextualTypes
    ): string {
        switch (contextualType) {
            case 'client':
                return SystemEntity.Client.toLowerCase();
                break;
            case 'party':
                return SystemEntity.Party.toLowerCase();
                break;
            case 'intermediary':
                return SystemEntity.Intermediary.toLowerCase();
                break;
            case 'adviser':
                return SystemEntity.Adviser.toLowerCase();
                break;
            default:
                throw new Error('contextualTypeName is not valid');
                break;
        }
    }
}
